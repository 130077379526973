import axios from "axios";

const baseUrl = () => {
  if (process.env.NODE_ENV === "development") {
    return "http://localhost:5000";
  } else if (process.env.NODE_ENV === "production") {
    return "/";
  }
};

const API = axios.create({
  baseURL: baseUrl(),
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

// It helps to send Token through headers
API.interceptors.request.use((req) => {
  if (localStorage?.getItem("accesskeyforenterintothesystem")) {
    req.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "accesskeyforenterintothesystem"
    )}`;
  }
  return req;
});

export const GetnonceMeta = (userData) =>
  API.post(`/api/auth/getNonce/${userData}`);

export const SignInMeta = (userData) =>
  API.post("/api/auth/metaMaskSignIn", userData);

export const GetUserNfts = () => API.get("/user/getNfts");

export const GetUserStakedNfts = () =>
  API.get("/user/getAllStakedNftAndTokens");

export const AddUserNftToStake = (data) =>
  API.post("/user/addNftsToClub", data);

export const RemoveUserNftFromStake = (data) =>
  API.post("/user/removeNftsAndTokensFromClub", data);

export const GetAllQuest = (data) => API.post("/user/getAllQuest", data);

export const UpdateProfile = (data) => API.post("/user/updateProfile", data);

export const GetUserProfile = () => API.get("/user/getProfile");

export const SendMessage = (data) => API.post("/user/sendMessage", data);

export const getMessageHistory = (data) =>
  API.get(`/user/getMessageHistory/${data}`);

export const getAllUser = () => API.get("/user/getUsersForChat");

export const getDashboardData = () => API.get("/user/getDashboard");
export const getRewardHistory = () => API.get("/user/getAirdropRewardHistory");

// Admin APIs
export const AdminLogin = (data) => API.post(`/api/auth/signin`, data);
export const GetAllUserInAdmin = (data) => API.post(`/admin/getAllUser`, data);
export const GetAllQuestAdmin = (data) => API.post("/admin/getAllQuest", data);
export const AddQuest = (data) => API.post("/admin/addQuest", data);
export const GetAnalysis = (data) => API.get("/admin/getAnalysis", data);

export const UpdateQuest = (id, data) =>
  API.post(`/admin/updateQuest/${id}`, data);

export const GetEligibleUserOfQuest = (data) =>
  API.get(`/admin/getEligibleUsersByQuest/${data}`);

export const BlockUser = (data) => API.post(`/admin/blockUser/${data}`);
export const DeleteUser = (data) => API.delete(`/admin/deleteuser/${data}`);
export const DeleteQuest = (data) => API.delete(`/admin/deleteQuest/${data}`);
