import React from "react";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as API from "../../../utils/axios/apis";
import "../../../Component/Home/Home.css";
import "../Admin.css";
const AdminLogin = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    // await API.AdminLogin(data).then((res) => {
    //   console.log("res", res);
    // });
    navigate("/admin/allUsers");
  };

  return (
    <div className="body-bg-admin">
      <div className="galaxy"></div>
      <div className="planet mercury"></div>
      <div className="stars"></div>
      <div className="mars"></div>
      <div className="star-1"></div>
      <div className="star2"></div> <div className="star3"></div>
      <div className="star4"></div> <div className="star5"></div>
      <div className="star6"></div>
      <div className="star7"></div>
      <div className="star8"></div>
      <div className="star9"></div>
      <div className="star11"></div>
      <div className="star10"></div> <div className="star11"></div>
      <div className="star12"></div>
      <div className="axo-mini"></div>
      <div className="axo-mini2"></div>
      <div className="container ">
        <ul class="nav ">
          <li class="nav-item mt-3">
            <NavLink
              to={"/"}
              className="navbar-brand"
              style={{ textDecoration: "none" }}
            >
              <img
                style={{ width: "10rem" }}
                className="mobilelogo"
                src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1714194361/Group_190_2_twdkte.png"
                alt=""
              />
            </NavLink>
          </li>
        </ul>
        {/* banner */}
        <div className="banner-section adminlogin">
          <div className="banner-admin banner container m-auto">
            <h2>Admin Login</h2>
          </div>
        </div>{" "}
      </div>{" "}
      <div className="bg-glow"></div>
      <div className="container cont-home-admin">
        <form onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="email">Email:</label>
          <input {...register("email", { required: true })} />
          {errors.email && <p className="error-msg">Email is required.</p>}
          <br /> <br />
          <label htmlFor="password">Password:</label>
          <input
            {...register("password", {
              minLength: 8,
              //   maxLength: 15,
              required: true,
            })}
          />
          {errors.password && <p className="error-msg">Password is required</p>}{" "}
          <br /> <br /> <br />
          <input type="submit" className="connect-wallet-btn" />
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;
