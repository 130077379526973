import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import moment from "moment";

import AirdropsModal from "./airdropsModal";
import CountdownTimer from "./CountdownTimer";
import * as API from "../../utils/axios/apis";

import "./Airdrops.css";

const Airdrops = () => {
  const [show, setShow] = useState(false);
  const [selectedQuest, setSelectedQuest] = useState({});
  const [questList, setQuestList] = useState(null);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  const handleClose = () => setShow(false);
  const handleShow = (item) => {
    setShow(true);
    setSelectedQuest(item);
  };

  useEffect(() => {
    (async () => {
      const payload = {
        size,
        pageNo: page,
      };
      const res = await API.GetAllQuest(payload);
      setQuestList(res.data.quests);
    })();
  }, [page]);

  return (
    <div>
      <div className="container">
        <div className="row">
          {questList ? (
            questList.map((item, i) => (
              <div
                key={Date.now() + i}
                className=" col-lg-3 col-md-6 col-12 mb-4"
              >
                <div className="airdrop-card mt-3">
                  <img
                    src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1713377007/logo-img_vrdgo3.png"
                    alt=""
                  />
                  <h1 className="level-num">level-{item["minimum level"]}</h1>
                  <Button className="quest" onClick={() => handleShow(item)}>
                    {item.title}
                  </Button>
                  <div className="timer">
                    <p>Expires On</p>
                    <CountdownTimer endDate={item.endDate} />
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No Data found</p>
          )}
        </div>
      </div>
      <AirdropsModal
        show={show}
        handleClose={handleClose}
        item={selectedQuest}
      />
    </div>
  );
};

export default Airdrops;
