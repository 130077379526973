import React, { useContext, useEffect, useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
// import LandingNavbar from "./LandingNavbar";
import SideNav from "./SideNav";
// import CommonNavbar from "./CommonNavbar/CommonNavbar";
// import Footer from "./Footer";
// import "./WebsiteLayout.css";
import Button from "react-bootstrap/Button";

import { shortheString } from "../helper";
import { connectedWalletAddress } from "../App";
const WebsiteLayout = () => {
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width:550px)").matches
  );
  useEffect(() => {
    window.matchMedia("(max-width:550px)").addEventListener("change", (e) => {
      setMatches(e.matches);
      window.location.reload();
    });

    // eslint-disable-next-line
  }, [matches]);
  const { walletAddress } = useContext(connectedWalletAddress);
  return (
    <>
      {matches ? (
        <div className="desktopViewNotSupport">
          <div className="text-white">
            <h1 className="px-3 text-center">
              DashBorad is only supported in Desktop View. Please adjust your
              screen resolution
            </h1>
          </div>
        </div>
      ) : (
        <div className="CommonComponent" style={{ background: "#0E0F0F" }}>
          {/* <CommonNavbar /> */}
          <div className="container-fluid" style={{ height: "100%" }}>
            <div className="row">
              <div className=" col-xl-2 col-lg-3 col-md-3 col-12 sidenav-bg ">
                <div className="leftsidebar">
                  <div className="logo" style={{ margin: "32px 0px" }}>
                    <NavLink className="navbar-brand navlogo" to={"/"}>
                      <img
                        style={{ width: "10rem" }}
                        src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1714194361/Group_190_2_twdkte.png"
                        alt=""
                      />
                    </NavLink>
                  </div>

                  <SideNav />
                </div>
              </div>

              <div
                className=" col-xl-10 col-lg-9 col-md-9 col-12"
                style={{
                  backgroundColor: "#0E0F0F",
                }}
              >
                <form
                  className=" navbtn d-flex"
                  role="search"
                  style={{ justifyContent: "end", padding: "10px" }}
                >
                  <Button
                    className="connect-wallet-btn"
                    variant=""
                    //   onClick={handleConnectWallet}
                  >
                    <span className="btnname">
                      {shortheString(walletAddress) ?? "walletAddress"}
                    </span>
                    <div className="wave" />
                  </Button>
                </form>
                <div className="Mainbody mt-5">
                  <Outlet />
                </div>
              </div>
            </div>
          </div>

          {/* <Footer /> */}
        </div>
      )}
    </>
  );
};

export default WebsiteLayout;
