import Web3Modal from "web3modal";

export const providerOptions = {
  walletconnect: {
    // package: WalletConnectProvider,
    options: {
      infuraId: "nnbUTklLxz7N-9Zoxw9Jl4yNio1PXtgx", // required nnbUTklLxz7N-9Zoxw9Jl4yNio1PXtgx
    },
  },
};

export const web3Modal = new Web3Modal({
  // network: "mainnet", // optional
  cacheProvider: false, // optional
  disableInjectedProvider: false,
  providerOptions, // required
});
