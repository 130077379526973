import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import * as API from "../../utils/axios/apis";
import { connectedWalletAddress } from "../../App";
import "./Profile.css";
import { local } from "web3modal";

const Profile = () => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();
  const [userDetail, setUserDetail] = useState(
    JSON.parse(localStorage.getItem("userDetail")) ?? null
  );

  useEffect(() => {
    if (!userDetail) {
      navigate("/dashboard");
    }
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      solanaWalletAddress: userDetail?.solanaWalletAddress,
      profilePicUrl: userDetail?.profilePicUrl,
      customUserName: userDetail?.name,
      twitterUserName: userDetail?.twitterUsername,
      telegramUserName: userDetail?.telegramUsername,
    },
  });
  const copyText = (e) => {
    e.preventDefault();
    const textToCopy = "0f9r8eiojkf";
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    setCopied(true);
    setShowTooltip(true);
    setTimeout(() => {
      setCopied(false);
      setShowTooltip(false);
    }, 2000);
  };
  const [file, setFile] = useState("");
  const [uploadpic, setUploadPic] = useState(userDetail?.profilepicurl);
  const [previousPic, setPreviousPic] = useState("");
  const [userNfts, setUserNfts] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    (async () => {
      const response = await API.GetUserNfts();
      setUserNfts(response.data.nfts);
    })();
  }, []);

  const handlePicSelection = (url) => {
    setPreviousPic(uploadpic);
    setUploadPic(url);
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    data.profilePicUrl = uploadpic;
    const response = await API.UpdateProfile(data);
    if (response.data.status) {
      toast.success("Profile updated successfully!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      reset({
        solanaWalletAddress: "",
        profilePicUrl: "",
        customUserName: "",
        twitterUserName: "",
        telegramUserName: "",
      });
      setIsSubmitting(false);
      setUploadPic("");
    } else {
      toast.error("Something went wrong, try again later!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setIsSubmitting(false);
    }
  };

  return (
    <div
      style={{
        background:
          "url(https://res.cloudinary.com/dbtsrjssc/image/upload/v1713444330/dashboardbackground_nnasjx.avif)",
        height: "auto",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        borderRadius: "22px",
      }}
    >
      <div className="container ">
        <div className="row">
          <div className="col-lg-12 formcol mt-5 mb-5">
            <form id="contact" onSubmit={handleSubmit(onSubmit)}>
              <div className="container">
                <div className="row">
                  <div className="col-4">
                    <div className="imgupload">
                      {/* <i class="fa-solid fa-square-pen fa-xl"></i> */}
                      <div class="dropdown">
                        <button
                          class="btn dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img
                            style={{ position: "relative" }}
                            src={
                              uploadpic ??
                              "https://res.cloudinary.com/dbtsrjssc/image/upload/v1712399479/KolKart/Gig/USER-ID1007_user%20%282%29.png"
                            }
                            alt=""
                          />
                          <img
                            className="edit"
                            src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1714473791/icons8-upload-64_uccbhn.png"
                            alt=""
                          />

                          <ul class="dropdown-menu menu-ul">
                            {userNfts &&
                              userNfts.map((item, index) => (
                                <li>
                                  <img
                                    className="dropdown-item dropimg"
                                    src={item.image_url}
                                    alt=""
                                    onClick={() =>
                                      handlePicSelection(item.image_url)
                                    }
                                  />
                                </li>
                              ))}
                          </ul>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="profile-info">
                      <div className="wallet-addr pt-2">
                        Wallet Address:{" "}
                        {`${userDetail?.walletAddress.slice(
                          0,
                          5
                        )}...${userDetail?.walletAddress.slice(-5)}`}{" "}
                        {!copied && (
                          <i className="fa fa-copy" onClick={copyText}></i>
                        )}
                        {copied && (
                          <i
                            className="fa fa-check"
                            style={{ color: "green" }}
                          ></i>
                        )}
                      </div>
                      <div className="axo-id pt-2">Axo ID: axo32909</div>{" "}
                      <div className="profile-username pt-2">
                        Username: {userDetail?.name}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="mb-3 mt-3 d-flex walletdiv"
                style={{ gap: "40px" }}
              >
                <label style={{ color: "#fff" }}>Wallet Address:</label>
                <input
                  type="text"
                  placeholder="Enter your solana address"
                  {...register("solanaWalletAddress")}
                />
                {/* {errors.solanaWalletAddress && (
                  <p className="error-msg">
                    Solana wallet address is required.
                  </p>
                )} */}
              </div>
              <div className="mb-3 d-flex twitterdiv" style={{ gap: "6rem" }}>
                <label style={{ color: "#fff" }}>Twitter:</label>
                <input
                  type="text"
                  placeholder="Enter your twitter Id"
                  {...register("twitterUserName", { required: true })}
                />
                {/* {errors.twitterUserName && (
                  <p className="error-msg">Twitter ID is required.</p>
                )} */}
              </div>
              <div className="mb-3 d-flex telegramdiv" style={{ gap: "5rem" }}>
                <label style={{ color: "#fff" }}>Telegram:</label>
                <input
                  type="text"
                  placeholder="Enter your telegram Id"
                  {...register("telegramUserName")}
                />
                {/* {errors.telegramUserName && (
                  <p className="error-msg">Telegram ID is required.</p>
                )} */}
              </div>
              {/* <input
                type="text"
                name="solana"
                placeholder="Enter Your Solana Address"
              /> */}
              <div className="mb-3 d-flex custumname" style={{ gap: "2.8rem" }}>
                <label style={{ color: "#fff" }}>Custom Name:</label>
                <input
                  type="text"
                  name="customUserName"
                  placeholder="Set username"
                  {...register("customUserName")}
                />
                {/* {errors.customUserName && (
                  <p className="error-msg">User name is required.</p>
                )} */}
              </div>
              <button
                type="submit"
                id="contact-submit"
                disabled={isSubmitting}
                style={{ borderRadius: "7px" }}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
