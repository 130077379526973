import axios from "axios";

export const shortheString = (hexString, length = 10, ellipsisLength = 3) => {
  if (!hexString || hexString.length <= length) {
    return hexString;
  }

  const prefixLength = length - ellipsisLength;
  const suffixLength = length - ellipsisLength;

  const prefix = hexString.substring(0, prefixLength);
  const suffix = hexString.substring(hexString.length - suffixLength);

  const truncatedHex = prefix + "..." + suffix;

  return truncatedHex;
};

export const checkAddress = () => {
  const token = localStorage.getItem("accesskeyforenterintothesystem");
  const getWalletAddress = localStorage.getItem("walletAddress");
  if (!token || !getWalletAddress) return;
  return getWalletAddress;
};

export const exchangeRateETHToUSD = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD")
      .then((response) => {
        const usdValue = response.data.USD;
        resolve(usdValue);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
