import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as API from "../../utils/axios/apis";
import Chat from "./Chat";
import { checkAddress } from "../../helper";

import "./Message.css";

const Messages = () => {
  const navigate = useNavigate();
  const [userList, setUserList] = useState(null);
  const [memoChild, setMemoChild] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [searchChat, setSearchChat] = useState([]);
  const [receiverUser, setReceiverUser] = useState({
    userName: "",
    userId: "",
  });

  useEffect(() => {}, [navigate]);

  useEffect(() => {
    const walletAddress = checkAddress();

    if (!walletAddress) return navigate("/");

    (async () => {
      await API.getAllUser().then((res) => {
        const list = res.data.messages.filter(
          (msg) => msg.walletAddress !== walletAddress
        );
        setUserList(list);
      });
    })();
  }, []);

  const fetchChatHistory = async (id) => {
    await API.getMessageHistory(id).then((res) =>
      setChatHistory(res.data.messages)
    );
  };
  // const [message, setMessage] = useState(false);
  // const openChat = () => {
  //   setMessage(true);
  // };

  const searchHandler = (e) => {
    const toSearch = e.target.value;
    const searchResult = userList.filter((item) =>
      item.userName.toLowerCase().includes(toSearch.toLowerCase())
    );
    setSearchChat(searchResult);
  };

  return (
    <div>
      <div className="container">
        <div className="row mainrow">
          <main className="maindiv">
            <div className="">
              {/* <li className="active">
                <i className="fa-regular fa-comment-dots" />
              </li> */}
              {/* <li>
                <i className="fa-solid fa-phone" />
              </li> */}
              {/* <li>
                <i className="fa-solid fa-gear" />
              </li> */}
              {/* <li>
                <i className="fa-solid fa-trash-can" />
              </li> */}
              {/* <li>
                <i className="fa-regular fa-star" />
              </li> */}
              {/* <li>
                <i className="fa-solid fa-address-book" />
              </li> */}
            </div>

            <div className="sideNav2">
              <div className="SideNavhead">
                <h4>Chats</h4>
                {/* <i className="fa-solid fa-filter" />
                <i className="fa-solid fa-user-plus" /> */}
              </div>
              <div className="SearchInputHolder">
                <i className="fa-solid fa-magnifying-glass" />
                <input
                  className="searchInput"
                  placeholder="Search For Chat.."
                  onChange={searchHandler}
                />
                <hr />
              </div>
              {searchChat.length > 0
                ? searchChat.map((item, index) => (
                    <li
                      className="group"
                      onClick={() => {
                        setReceiverUser(item);
                        setMemoChild(!memoChild);
                        fetchChatHistory(item.userId);
                      }}
                    >
                      <div className="avatar">
                        <img
                          src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1713377007/logo-img_vrdgo3.png"
                          alt=""
                        />
                      </div>
                      <p className="GroupName">{item.userName}</p>
                    </li>
                  ))
                : userList &&
                  userList.map((item, index) => (
                    <li
                      className="group"
                      onClick={() => {
                        setReceiverUser(item);
                        setMemoChild(!memoChild);
                        fetchChatHistory(item.userId);
                      }}
                    >
                      <div className="avatar">
                        <img
                          src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1713377007/logo-img_vrdgo3.png"
                          alt=""
                        />
                      </div>
                      <p className="GroupName">{item.userName}</p>
                    </li>
                  ))}
            </div>

            <section className="Chat">
              <Chat
                receiverUser={receiverUser}
                userList={userList}
                memoChild={memoChild}
                fetchChatHistory={fetchChatHistory}
                chatHistory={chatHistory}
              />
            </section>
          </main>
        </div>
      </div>
    </div>
  );
};

export default Messages;
