import React, { useState } from "react";
import './Bridge.css'

const Bridge = () => {
    const [checkedCards, setCheckedCards] =useState([]);
    const handleCardCheck = (index) => {
        if (checkedCards.includes(index)) {
          
          setCheckedCards(checkedCards.filter((item) => item !== index));
        } else {
          setCheckedCards([...checkedCards, index]);
        }
   
      };
  return (
    <div>
      <div className="container">
        {/* <div className="row"> */}
            {/* <div className="col-12"> */}
                <div className="nftBridge">
                    <div className="innerbridgediv ">
                    {/* <h1 className="">NFT Bridge</h1> */}
                    <div className="bridgebutton d-flex">
                        <div>
                        <button
                    className='bridgebtn'
                    >Click To Bridge NFT(2)</button> 
                        </div>
                        <div>
                        <button
                    className='bridgebtn'
                    >View ALl Bridge</button> 
                        </div>
                    </div>
                   
                    
                    {/* <div className="container"> */}
              {/* <div className="row"> */}
                <div className="row mb-5">
                  {[1, 2, 3, 4,5,6,7,8].map((value, i) => (
                    <div key={i} className=" col-xl-3 col-lg-4 col-md-6 col-12 mt-3">
                      <label className="c-custom-checkbox ">
                        <input
                          type="checkbox"
                          onClick={() => handleCardCheck(i)}
                        />
                        <svg
                          className="bridgechcekbox "
                          viewBox="0 0 60 40"
                          aria-hidden="true"
                          focusable="false"
                        >
                          <path
                            d="M21,2 C13.4580219,4.16027394 1.62349378,18.3117469 3,19 C9.03653312,22.0182666 25.2482171,10.3758914 30,8 C32.9363621,6.53181896 41.321398,1.67860195 39,4 C36.1186011,6.8813989 3.11316157,27.1131616 5,29 C10.3223659,34.3223659 30.6434647,19.7426141 35,18 C41.2281047,15.5087581 46.3445303,13.6554697 46,14 C42.8258073,17.1741927 36.9154967,19.650702 33,22 C30.3136243,23.6118254 17,31.162498 17,34 C17,40.4724865 54,12.4064021 54,17 C54,23.7416728 34,27.2286213 34,37"
                            stroke-width="4"
                            fill="none"
                            stroke-dasharray="270"
                            stroke-dashoffset="270"
                          ></path>
                        </svg>
                      </label>
                      <div className="nftcards">
                        <div className="cardnumber">
                          <p> #1,234</p>
                        </div>
                        <div className="nftimg bridgenft">
                          <img
                            src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1712647025/5bad6efcc2862574dcdf770c6698ddd2_v02t0k.webp"
                            alt=""
                          />
                        </div>
                        <div className="card_data bridge-data d-flex">
                          <div className="cardname bridgecardname">
                            <p>Axolittle #3456</p>
                            <p className="cardrate">0.0027 ETH</p>
                          </div>
                          {/* <div className="viewbtn bridgeview">
                            <button>VIEW NFT</button>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            {/* </div> */}
                    </div>
                </div>
            </div>
    //     </div>
    //   </div>
    // </div>
  )
}

export default Bridge
