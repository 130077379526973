import React, { useContext } from "react";
import Button from "react-bootstrap/Button";

import { connectedWalletAddress } from "../../App";
import { shortheString } from "../../helper";

import "./CommonNavbar.css";
import { NavLink } from "react-router-dom";

const CommonNavbar = () => {
  const { walletAddress } = useContext(connectedWalletAddress);
  return (
    <div>
    <ul class="nav">
  <li class="nav-item mt-3">
  <NavLink
                to={"/"}
                className="navbar-brand"
                style={{ textDecoration: "none" }}
              >
                <img style={{width:'10rem'}} className='mobilelogo' src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1714194361/Group_190_2_twdkte.png" alt="" />
              </NavLink>
  </li>
  <form className=" ms-auto mt-3 indexplanet" role="search ">
                  <button
                    className="btn connect-wallet-btn"
                    type="button"
                    onClick={() => {
                      if (!walletAddress) {
                        // connectWalletHandler();
                      }
                    }}
                  >
                    {walletAddress
                      ? shortheString(walletAddress)
                      : "Connect Wallet"}
                  </button>
                </form>

</ul>
 </div>
   
  );
}



export default CommonNavbar;
