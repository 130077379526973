import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

// import "./AgmiLock.css";
const SideNav = () => {
  const initialValues = {
    dashboard: false,
    profile: false,
    message: false,
    airdrops: false,
    rewards: false,
    stake: false,
    perks: false,
    bridge: false,
    faq: false,
  };

  const [sideActive, setSideActive] = useState(initialValues);
  return (
    <nav
      className="navbar navbar-expand-lg navbar-dashboard"
      style={{ padding: "0px" }}
    >
      {/* <a className="navbar-brand" href="#">Navbar</a> */}
      {/* <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContents"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button> */}
      <div
        // className="collapse navbar-collapse"

        id="navbarSupportedContents"
      >
        <div className="w-100" style={{ height: "116vh" }}>
          {/* <div className="profile-navbar">
            <div className="userimg">
              <img
                src="https://besturls.com/assets/images/users/149071.png"
                alt=""
                style={{ width: "60px" }}
              />
            </div>
            <div className="username">
              {" "}
              <h6 style={{ fontWeight: "600", fontSize: "18px" }}>Welcome!</h6>
            </div>
          </div> */}
          <div className="profile-sidenav-data">
            <NavLink className="Links" to="/dashboard">
              <div
                className={
                  sideActive.dashboard
                    ? "sidenavSingle active"
                    : "sidenavSingle"
                }
                onClick={() =>
                  setSideActive({ ...initialValues, dashboard: true })
                }
              >
                <div className="sidenav-flex">
                  <div className="sidenavImage">
                    <img
                      src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1713851692/icons8-dashboard-50_vp5ysp.png"
                      alt=""
                    />
                  </div>
                  Dashboard
                </div>
              </div>
            </NavLink>
            <NavLink className="Links" to="/dashboard/profile">
              <div
                className={
                  sideActive.profile ? "sidenavSingle active" : "sidenavSingle"
                }
                onClick={() =>
                  setSideActive({ ...initialValues, profile: true })
                }
              >
                <div className="sidenav-flex">
                  <div className="sidenavImage">
                    <img
                      src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1713851601/icons8-male-user-32_mxdlmz.png"
                      alt=""
                    />
                  </div>
                  Profile
                </div>
              </div>
            </NavLink>
            <NavLink className="Links" to="/dashboard/airdrops">
              <div
                className={
                  sideActive.airdrops ? "sidenavSingle active" : "sidenavSingle"
                }
                onClick={() =>
                  setSideActive({ ...initialValues, airdrops: true })
                }
              >
                <div className="sidenav-flex">
                  <div className="sidenavImage">
                    <img
                      src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1714468848/icons8-gift-50_gejus3.png"
                      alt=""
                    />
                  </div>
                  <div className="">Airdrops</div>
                </div>
              </div>{" "}
            </NavLink>
            <NavLink className="Links" to="/dashboard/rewards">
              <div
                className={
                  sideActive.rewards ? "sidenavSingle active" : "sidenavSingle"
                }
                onClick={() =>
                  setSideActive({ ...initialValues, rewards: true })
                }
              >
                <div className="sidenav-flex">
                  <div className="sidenavImage">
                    <img
                      src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1712816782/wallet_vuwscq_1_1_nt5woa.png"
                      alt=""
                    />
                  </div>
                  <div className="">Rewards</div>
                </div>
              </div>
            </NavLink>
            <NavLink className="Links" to="/dashboard/message">
              <div
                className={
                  sideActive.message ? "sidenavSingle active" : "sidenavSingle"
                }
                onClick={() =>
                  setSideActive({ ...initialValues, message: true })
                }
              >
                <div className="sidenav-flex">
                  <div className="sidenavImage">
                    <img
                      src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1713851603/icons8-message-50_1_jcioph.png"
                      alt=""
                    />
                  </div>
                  Message
                </div>
              </div>
            </NavLink>
            {/* <div
              className={
                sideActive.perk ? "sidenavSingle active" : "sidenavSingle"
              }
              onClick={() => setSideActive({ ...initialValues, perk: true })}
            >
              <NavLink className="Links" to="/dashboard/perks">
                <div className="sidenav-flex">
                  <div className="sidenavImage">
                    <img
                      src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1712816499/data-transfer_jhdsip_2_1_1_uyo50e.png"
                      alt=""
                    />
                  </div>
                  Perk
                </div>
              </NavLink>
            </div> */}
            <NavLink className="Links" to="/dashboard/AgmiLock">
              <div
                className={
                  sideActive.stake ? "sidenavSingle active" : "sidenavSingle"
                }
                onClick={() => setSideActive({ ...initialValues, stake: true })}
              >
                <div className="sidenav-flex">
                  <div className="sidenavImage">
                    <img
                      src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1712816499/data-transfer_jhdsip_2_1_1_uyo50e.png"
                      alt=""
                    />
                  </div>
                  AGMI Locker
                </div>
              </div>
            </NavLink>
            <NavLink className="Links" to="/dashboard/nft-bridge">
              <div
                className={
                  sideActive.bridge ? "sidenavSingle active" : "sidenavSingle"
                }
                onClick={() =>
                  setSideActive({ ...initialValues, bridge: true })
                }
              >
                <div className="sidenav-flex">
                  <div className="sidenavImage">
                    <img
                      src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1713851236/icons8-transfer-50_1_nk4qnr.png"
                      alt=""
                    />
                  </div>
                  NFT Bridge
                </div>
              </div>
            </NavLink>
            <NavLink className="Links" to="/dashboard/faq">
              <div
                className={
                  sideActive.faq ? "sidenavSingle active" : "sidenavSingle"
                }
                onClick={() => setSideActive({ ...initialValues, faq: true })}
              >
                <div className="sidenav-flex">
                  <div className="sidenavImage">
                    <img
                      src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1714037477/icons8-faq-50_ukrvay.png"
                      alt=""
                    />
                  </div>
                  FAQ
                </div>
              </div>
            </NavLink>
          </div>{" "}
        </div>
      </div>
    </nav>
  );
};

export default SideNav;
