import React from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
// import * as API from "../../../utils/axios/apis";
import * as API from "../../utils/axios/apis";
const DeleteModal = ({
  showDeleteModal,
  setShowDeleteModal,
  toDeleteUserWalletAddr,
  getUserFunc,
  setToDeleteUserQuestId,
  toDeleteUserQuestId,
  quest,
  getAllQuest,
}) => {
  const deleteUserbyWalletAddress = async (data) => {
    await API.DeleteUser(data).then((res) => {
      console.log("resp", res);

      if (res.data.status) {
        getUserFunc();
        toast.success("Deleted user Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Please try again later", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      setShowDeleteModal(false);
    });
  };
  const deleteUserbyQuestID = async (data) => {
    await API.DeleteQuest(data).then((res) => {
      console.log("resp", res);
      if (res.data.status) {
        getAllQuest();
        toast.success("Deleted quest successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Please try again later", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      setShowDeleteModal(false);
    });
  };
  const handlerDelete = () => {};
  return (
    <div>
      <Modal
        id="deleteTokenModal"
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
      >
        <Modal.Header className="border-0 pb-0 ">
          <div className="h3 cursor-pointer ms-auto"></div>
        </Modal.Header>

        <Modal.Body>
          <div className="">
            <div className="">
              <div className="deletemodal">
                <div
                  className="mt-3"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <h5 className=" mb-5 headingColor  text-center">
                    {/* {deleteText} */}
                    Are you sure you want to delete this user?
                  </h5>
                  <div className="d-flex gap-5">
                    <button
                      onClick={() =>
                        quest
                          ? deleteUserbyQuestID(toDeleteUserQuestId)
                          : deleteUserbyWalletAddress(toDeleteUserWalletAddr)
                      }
                      className="flip mb-4"
                      style={{ width: "100px" }}
                    >
                      Yes
                    </button>{" "}
                    <button
                      onClick={() => setShowDeleteModal(false)}
                      className="flip mb-4"
                      style={{ width: "100px" }}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DeleteModal;
