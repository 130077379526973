import { toast } from "react-toastify";
import moment from "moment";
import { useForm } from "react-hook-form";

import * as API from "../../../utils/axios/apis";
import "../Admin.css";
import { useEffect, useState } from "react";
import DeleteModal from "../../../Common/Modal/DeleteModal";
import Pagination from "../../../Common/Pagination/Pagination";
const AdminAddQuest = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [toDeleteUserQuestId, setToDeleteUserQuestId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [questList, setQuestList] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [questtable, setQuestTable] = useState(true);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [addEditQuestTable, setAddEditQuestTable] = useState({
    add: false,
    show: false,
    selectedData: null,
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      minLevel: 0,
      secondTaskTitle: "",
      thirdTaskTitle: "",
      tweetId: "",
      endDate: "",
      questId: "",
    },
  });

  const onSubmit = async (data) => {
    data.endDate = moment(data.endDate).format();
    let response;

    if (addEditQuestTable.add) {
      response = await API.AddQuest(data);
    } else {
      response = await API.UpdateQuest(data.questId, data);
    }
    if (response.data.status) {
      toast.success(
        addEditQuestTable.add
          ? "Quest added successfully!"
          : "Quest updated successfully!",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
      if (!addEditQuestTable.add) {
        setAddEditQuestTable({
          add: false,
          show: false,
          selectedData: null,
        });
        setQuestTable(true);
      }
      reset({
        title: "",
        minLevel: 0,
        secondTaskTitle: "",
        thirdTaskTitle: "",
        tweetId: "",
        endDate: "",
      });
    } else {
      toast.error("Something went wrong, try again later!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  const list = [
    {
      airdropId: "abc123",
      userWalletAddress: "0xabc123banchfgt",
      rewardCurrency: "AGMI token",
      rewardsEarned: "abcbcbc",
    },
    {
      airdropId: "abc1232",
      userWalletAddress: "0xabc123banchfgt",
      rewardCurrency: "AGMI token",
      rewardsEarned: "abcbcbc",
    },
    {
      airdropId: "abc1233",
      userWalletAddress: "0xabc123banchfgt",
      rewardCurrency: "AGMI token",
      rewardsEarned: "abcbcbc",
    },
    {
      airdropId: "abc1234",
      userWalletAddress: "0xabc123banchfgt",
      rewardCurrency: "AGMI token",
      rewardsEarned: "abcbcbc",
    },
  ];

  const getAllQuest = async () => {
    const payload = {
      size,
      pageNo: page,
    };
    await API.GetAllQuestAdmin(payload).then((response) =>
      setQuestList(response.data.quests)
    );
  };
  useEffect(() => {
    getAllQuest();
  }, [addEditQuestTable, page]);

  const handleRowClick = async (questId) => {
    // await API.GetEligibleUserOfQuest(questId).then((response) =>
    //   console.log("response.data", response.data)
    // );
    setSelectedTask(questId);
    // setShowModal(true);
  };
  const blockHandler = async (data, index) => {
    await API.BlockUser(data.userId).then((res) => {
      setToggle(!toggle);
    });
  };
  const addquest = (choose, item = null) => {
    setAddEditQuestTable({
      status: !addEditQuestTable.status,
      add: choose === "toAdd" ? true : false,
      selectedData: item,
    });
    if (choose === "toEdit") {
      setValue("title", item.title);
      setValue("minLevel", item.minLevel);
      setValue("secondTaskTitle", item.secondTaskTitle);
      setValue("thirdTaskTitle", item.thirdTaskTitle);
      setValue("tweetId", item.tweetId);
      setValue("endDate", moment(item.endDate).format("YYYY-MM-DD"));
      setValue("questId", item.questId);
    }
    setQuestTable(!questtable);
  };
  const deleteHandler = (questId) => {
    setShowDeleteModal(true);
    setToDeleteUserQuestId(questId);
  };
  return (
    <div style={{ width: "78rem" }}>
      {" "}
      <div className="addquest mb-5">
        <button onClick={() => addquest("toAdd")}>
          {addEditQuestTable.status ? "Cancel" : "Add Quest"}
        </button>
      </div>
      <div className="admin-add-quest admin-get-eligible">
        <div className="container m-auto">
          {questtable && (
            <>
              <div
                className="formik-container mb-5"
                style={{ overflow: "auto" }}
              >
                <table className="table-container">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Level</th>
                      <th>Second Task</th>
                      <th>Third Task</th>
                      <th>To Tweet</th>
                      <th>End Date</th>

                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {questList.map((task, index) => (
                      <tr
                        key={index}
                        onClick={() => handleRowClick(task.questId)}
                      >
                        <td>{task.title}</td>
                        <td>{task.minLevel}</td>
                        {/* <td>task1</td> */}
                        <td>{task.secondTaskTitle}</td>
                        <td>{task.thirdTaskTitle}</td>
                        <td>{task.tweetId}</td>
                        <td>{moment(task.endDate).format("DD-MM-YYYY")}</td>
                        <td>
                          <button
                            className="blockuserbtn"
                            onClick={() => addquest("toEdit", task)}
                          >
                            Edit
                          </button>
                        </td>
                        <td>
                          <button
                            className="blockuserbtn"
                            onClick={() => deleteHandler(task.questId)}
                          >
                            Delete
                          </button>
                        </td>
                        <td>
                          <button
                            className="blockuserbtn"
                            // onClick={() => blockHandler(task, index)}
                          >
                            Eligible
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination className="mt-5" setPage={setPage} page={page} />
            </>
          )}

          {addEditQuestTable.status && (
            <div className="admin-add-quest mb-5">
              {/* <div className="container m-auto"> */}
              <h3>{addEditQuestTable.add ? "Add Quest" : "Edit Quest"}</h3>
              <div className="formik-container m-auto">
                <form className="formik-form" onSubmit={handleSubmit(onSubmit)}>
                  <div className="d-flex flex-column">
                    <div className="title-div">
                      <label htmlFor="title">Title:</label>
                      <input
                        type="text"
                        {...register("title", { required: true })}
                      />
                    </div>
                    <div>
                      {errors.title && (
                        <p className="error-msg">Title is required.</p>
                      )}
                    </div>
                  </div>
                  <br />
                  <div className="d-flex flex-column">
                    <div className="level-div">
                      <label htmlFor="minLevel">Level:</label>
                      <input
                        type="number"
                        {...register("minLevel", {
                          required: true,
                          valueAsNumber: true,
                          validate: (value) => value > 0,
                        })}
                      />
                    </div>
                    <div>
                      {errors.minLevel && (
                        <p className="error-msg">Level is required.</p>
                      )}
                    </div>
                  </div>
                  <br />
                  <div className="d-flex flex-column">
                    <div className="task2-div">
                      <label htmlFor="secondTaskTitle">Second task:</label>
                      <input
                        type="text"
                        {...register("secondTaskTitle", { required: true })}
                      />
                    </div>
                    <div>
                      {errors.secondTaskTitle && (
                        <p className="error-msg">
                          Second task title is required.
                        </p>
                      )}
                    </div>
                  </div>
                  <br />
                  <div className="d-flex flex-column">
                    <div className="task2-div">
                      <label htmlFor="thirdTaskTitle">Third task:</label>
                      <input
                        type="text"
                        {...register("thirdTaskTitle", { required: true })}
                      />
                    </div>
                    <div>
                      {errors.thirdTaskTitle && (
                        <p className="error-msg">
                          Third task title is required.
                        </p>
                      )}{" "}
                    </div>
                  </div>
                  <br />
                  <div className="d-flex flex-column">
                    <div className="task2-div">
                      <label htmlFor="tweetId">Twitter ID:</label>
                      <input
                        type="text"
                        {...register("tweetId", { required: true })}
                      />
                    </div>
                    <div>
                      {errors.tweetId && (
                        <p className="error-msg">Tweet Id is required.</p>
                      )}
                    </div>
                  </div>
                  <br />
                  <div className="d-flex flex-column">
                    <div className="task2-div">
                      <label htmlFor="endDate">End Date:</label>
                      <input
                        type="date"
                        className="date-input"
                        {...register("endDate", { required: true })}
                      />
                    </div>
                    <div>
                      {errors.endDate && (
                        <p className="error-msg">End date is required.</p>
                      )}
                    </div>
                  </div>
                  <br />
                  <button
                    type="submit"
                    className="submit-btn-admin m-auto mt-3"
                  >
                    Submit
                  </button>
                </form>
              </div>
              {/* </div>{" "} */}
            </div>
          )}
        </div>
      </div>
      {showDeleteModal && (
        <DeleteModal
          showDeleteModal={showDeleteModal}
          toDeleteUserQuestId={toDeleteUserQuestId}
          setShowDeleteModal={setShowDeleteModal}
          quest={true}
          getAllQuest={getAllQuest}
        />
      )}
    </div>
  );
};

export default AdminAddQuest;
