import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const airdropsModal = ({ show, handleClose, item }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Body>
        <h1>QUEST</h1>
        <p>1. {item.title}</p>
        <p>2. {item.secondTaskTitle}</p>
        <p>3. {item.thirdTaskTitle}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default airdropsModal;
