import React, { useState, useEffect } from "react";
import moment from "moment";

import * as API from "../../../utils/axios/apis";

const AdminGetEligible = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [questList, setQuestList] = useState([]);

  const taskData = [
    {
      title: "Title 1",
      level: "Level 1",
      secondtask: "Task 2 Description",
      thirdtask: "Task 3 Description",
      twitterID: "user1",
      endDate: "2024-05-10",
    },
    {
      title: "Title 2",
      level: "Level 2",
      secondtask: "Task 2 Description",
      thirdtask: "Task 3 Description",
      twitterID: "user2",
      endDate: "2024-04-30",
    },
    {
      title: "Title 2",
      level: "Level 5",
      secondtask: "Task 2 Description",
      thirdtask: "Task 3 Description",
      twitterID: "user3",
      endDate: "2024-04-30",
    },
    {
      title: "Title 2",
      level: "Level 1",
      secondtask: "Task 2 Description",
      thirdtask: "Task 3 Description",
      twitterID: "user4",
      endDate: "2024-04-30",
    },
    {
      title: "Title 2",
      level: "Level 6",
      secondtask: "Task 2 Description",
      thirdtask: "Task 3 Description",
      twitterID: "user5",
      endDate: "2024-04-30",
    },
  ];

  const list = [
    {
      airdropId: "abc123",
      userWalletAddress: "0xabc123banchfgt",
      rewardCurrency: "AGMI token",
      rewardsEarned: "abcbcbc",
    },
    {
      airdropId: "abc1232",
      userWalletAddress: "0xabc123banchfgt",
      rewardCurrency: "AGMI token",
      rewardsEarned: "abcbcbc",
    },
    {
      airdropId: "abc1233",
      userWalletAddress: "0xabc123banchfgt",
      rewardCurrency: "AGMI token",
      rewardsEarned: "abcbcbc",
    },
    {
      airdropId: "abc1234",
      userWalletAddress: "0xabc123banchfgt",
      rewardCurrency: "AGMI token",
      rewardsEarned: "abcbcbc",
    },
  ];

  useEffect(() => {
    (async () => {
      await API.GetAllQuest().then((response) =>
        setQuestList(response.data.quests)
      );
    })();
  }, []);

  const handleRowClick = async (questId) => {
    await API.GetEligibleUserOfQuest(questId).then((response) =>
      console.log("response.data", response.data)
    );
    setSelectedTask(questId);
    setShowModal(true);
  };
  const data= [
    {
      name: "Total user count",
      count: 1
    },
    {
      name: "Total user participated",
      count: 0
    },
    {
      name: "Total token staked",
      count: 0
    },
    {
      name: "Total NFT staked",
      count: 0
    }
  ];
const GetAnalysis=async()=>{
  try {
    let response=await API.GetAnalysis([{}])
    console.log(response.data.data,'rsp');
  } catch (error) {
    console.log(error)
  }
}
useEffect(()=>{
  GetAnalysis();
},[])
  return (
    <div>
      
      {/* <div className="admin-add-quest admin-get-eligible">
        <div className="container m-auto">
          <div className="formik-container" style={{ overflow: "auto" }}>
            <table className="table-container">
              <thead>
                <tr>
                  <th>User Count</th>
                  <th>Participated Count</th>
                  <th>Staked Count</th>
                  <th>Total NFT Count</th>
                
                </tr>
              </thead>
              <tbody>
                {questList.map((task, index) => (
                  <tr key={index} onClick={() => handleRowClick(task.questId)}>
                    <td>{task.userCount}</td>
                    <td>{task.participatedUserCount}</td>
                    <td>{task.stakedTokenCount}</td>
                    <td>{task.totalNftCount}</td>
                  
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {showModal && (
            <div className="modal">
              <div className="modal-content">
                <span
                  className="close ms-auto"
                  onClick={() => setShowModal(false)}
                >
                  ×
                </span>
                <h4>
                  {" "}
                  <b>Users Eligible for Task</b>
                </h4>
                <table>
                  <thead>
                    <tr>
                      <th>Slno.</th>
                      <th>Airdrop ID</th>
                      <th>User wallet address</th>
                      <th>Reward currency</th>
                      <th>Rewards earned</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.map((task, index) => (
                      <tr
                        key={index}
                        onClick={() => handleRowClick(task.questId)}
                      >
                        <td>{index + 1}</td>
                        <td>{task.airdropId}</td>
                        <td>{task.userWalletAddress}</td>
                        <td>{task.rewardCurrency}</td>
                        <td>{task.rewardsEarned}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div> */}
      <div className="container">
        <div className="row">
        {data.map((value, i) => (
                    <div key={i} className=" col-lg-3 col-md-3 col-12 mt-3">
                     <div className="admin-analysis-card mt-5">
                      <div className="admin-analysis-data">
                     
                      <h3>{value.name}</h3>
                      <div className="count-analysis">
                      <h3>{value.count}</h3>
                     </div>
                      </div>
                   
                     </div>
                    </div>
                  ))}
        </div>
      </div>

    </div>
  );
};

export default AdminGetEligible;
