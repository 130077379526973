import React from "react";
import "./Pagination.css";
const Pagination = ({ setPage, page }) => {
  return (
    <div className="m-auto">
      <div className="pagination">
        <button className="btn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="btn--icon"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>
        <div className="">
          <ul className="d-flex flex-row align-items-center ">
            <li
              className={`page ${page === 1 ? "active" : ""}`}
              onClick={() => setPage(1)}
            >
              1
            </li>
            <li
              className={`page ${page === 2 ? "active" : ""}`}
              onClick={() => setPage(2)}
            >
              2
            </li>
            <li
              className={`page ${page === 3 ? "active" : ""}`}
              onClick={() => setPage(3)}
            >
              3
            </li>
            <li
              className={`page ${page === 4 ? "active" : ""}`}
              onClick={() => setPage(4)}
            >
              4
            </li>
            <li
              className={`page ${page === 5 ? "active" : ""}`}
              onClick={() => setPage(5)}
            >
              5
            </li>
            <li
              className={`page ${page === 6 ? "active" : ""}`}
              onClick={() => setPage(6)}
            >
              6
            </li>
            <li className="page">...</li>
            <li
              className={`page ${page === 23 ? "active" : ""}`}
              onClick={() => setPage(23)}
            >
              23
            </li>
          </ul>
        </div>
        <button className="btn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="btn--icon"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Pagination;
