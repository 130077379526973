import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import * as API from "../../../utils/axios/apis";
import DeleteModal from "../../../Common/Modal/DeleteModal";
import Pagination from "../../../Common/Pagination/Pagination";
const AllUser = () => {
  const [userList, setUserList] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [toDeleteUserWalletAddr, setToDeleteUserWalletAddr] = useState("");
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const getUserFunc = async () => {
    const payload = {
      size,
      pageNo: page,
    };
    await API.GetAllUserInAdmin(payload).then((res) =>
      setUserList(res.data.users)
    );
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    getUserFunc();
  }, [toggle, page]);

  console.log("userlist", userList);

  const handleRowClick = (id) => {
    console.log("id", id);
  };

  const blockHandler = async (data, index) => {
    await API.BlockUser(data.userId).then((res) => {
      setToggle(!toggle);
    });
  };
  const deleteHandler = (walletAddress) => {
    setShowDeleteModal(true);
    setToDeleteUserWalletAddr(walletAddress);
  };
  const shortenAddress = (address) => {
    return `${address.slice(0, 5)}...${address.slice(-5)}`;
  };
  return (
    <div>
      <div className="admin-add-quest admin-get-eligible">
        <div className="container m-auto">
          <div
            className="formik-container"
            style={{ overflow: "auto", width: "75rem" }}
          >
            <table className="table-container">
              <thead>
                <tr>
                  <th>Slno.</th>
                  <th>User Id</th>
                  <th>User Name</th>
                  <th>Level</th>
                  <th>Total NFT Staked</th>
                  <th>Total Token Staked</th>
                  <th>Wallet Address</th>
                  <th>Solana Address</th>
                  <th>Twitter ID</th>
                  <th>Telegram ID</th>
                  <th>Total Rewards Earn</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {userList.map((task, index) => (
                  <tr key={index} onClick={() => handleRowClick(task.questId)}>
                    <td>{index + 1}</td>
                    <td>{task.userId}</td>
                    <td>{task.userName}</td>
                    <td>
                      <Button
                        onClick={handleShow}
                        style={{ background: "none", border: "none" }}
                      >
                        07
                      </Button>

                      <Modal show={show} onHide={handleClose} centered>
                        <Modal.Header
                          closeButton
                          style={{
                            borderBottom: "none",
                            background: "#252740",
                            color: "#fff",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-x"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                          </svg>
                        </Modal.Header>
                        <Modal.Body
                          style={{ background: "#252740", color: "#fff" }}
                        >
                          <div
                            className="formik-container"
                            style={{ overflow: "auto", width: "45rem" }}
                          >
                            <table className="table-container modal-user">
                              <thead>
                                <tr>
                                  <th>Slno.</th>
                                  <th>Quest Name</th>
                                  <th>Level</th>
                                  <th>Wallet Address</th>
                                  <th>Start Date</th>
                                  <th>Completed</th>
                                </tr>
                              </thead>
                              <tbody className="mt-5">
                                {userList.map((task, index) => (
                                  <tr
                                    key={index}
                                    onClick={() => handleRowClick(task.questId)}
                                  >
                                    <td>{index + 1}</td>
                                    <td>{task.userId}</td>

                                    <td>{task.level}</td>
                                    <td>
                                      {shortenAddress(task.walletAddress)}
                                    </td>
                                    <td>{task.date}</td>
                                    <td>Completed</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </Modal.Body>
                      </Modal>
                    </td>
                    <td>{task.nft}</td>
                    <td>{task.token}</td>
                    <td>{shortenAddress(task.walletAddress)}</td>
                    <td>{shortenAddress(task.walletAddress)}</td>
                    <td>{task.twitter}</td>
                    <td>{task.telegram}</td>
                    <td>{task.rewardearn}</td>
                    <td>
                      <button className="blockuserbtn">Edit</button>
                    </td>
                    <td>
                      <button
                        className="blockuserbtn"
                        onClick={() => deleteHandler(task.walletAddress)}
                        // onClick={() => setShowDeleteModal(true)}
                      >
                        {/* {showModal && (
                        )} */}
                        Delete
                      </button>
                    </td>
                    <td>
                      <button
                        className="blockuserbtn"
                        onClick={() => blockHandler(task, index)}
                      >
                        {task.block ? "Unblock" : "Block"}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <Pagination className="mt-5" setPage={setPage} page={page} />
      </div>
      {showDeleteModal && (
        <DeleteModal
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          toDeleteUserWalletAddr={toDeleteUserWalletAddr}
          setUserList={setUserList}
          getUserFunc={getUserFunc}
        />
      )}
    </div>
  );
};

export default AllUser;
