import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { checkAddress } from "../../helper";
import * as API from "../../utils/axios/apis";
import "./AgmiLock.css";
const AgmiLock = () => {
  const navigate = useNavigate();

  const [addtoclub, setAddToClub] = useState(false);
  const [clubemember, setClubMember] = useState(false);
  const [checkedCards, setCheckedCards] = useState([]);
  const [show, setShow] = useState(false);
  const [showconformation, setShowConformation] = useState(false);

  const [toStake, setToStake] = useState([]);
  const [showNftList, setShowNftList] = useState([]);
  const [showNftToggle, setShowNftToggle] = useState(true);

  useEffect(() => {
    if (!checkAddress()) navigate("/");
  }, [navigate]);

  const fetchUnstakeNfts = async () => {
    const response = await API.GetUserNfts();
    setShowNftList(response.data.nfts);
    setToStake([]);
  };

  const fetchStakedNfts = async () => {
    const res = await API.GetUserStakedNfts();
    setShowNftList(res.data.nftList);
    setToStake([]);
  };

  useEffect(() => {
    if (showNftToggle) {
      fetchUnstakeNfts();
    } else {
      fetchStakedNfts();
    }
  }, []);

  const handleClose = () => setShow(false);
  const handleClosemodal = () => setShowConformation(false);
  const handleShow = () => setShow(true);
  const handleShowconformation = () => {
    setShowConformation(true);
    setShow(false);
  };

  const stakeHandler = (item) => {
    if (item.presentInClub) {
      const newStake = toStake.filter(
        (items) => items.identifier !== item.identifier
      );
      setToStake(newStake);
      item.presentInClub = false;
    } else {
      setToStake([...toStake, item]);
      item.presentInClub = true;
    }
  };

  const submitNftStake = async () => {
    await API.AddUserNftToStake({
      nftList: toStake,
      tokens: toStake.length,
    });
    await fetchUnstakeNfts();
  };

  const submitNftUnstake = async () => {
    const unstakeNftArr = toStake.map((item) => {
      return item.identifier;
    });
    const unStakeRes = await API.RemoveUserNftFromStake({
      nftIdentifiers: unstakeNftArr,
      tokensToRemove: 0,
    });
    await fetchStakedNfts();
  };

  const stakeNftHandler = async () => {
    if (showNftToggle) {
      // setShowNftList(stakeNftList);
      await fetchStakedNfts();
    } else {
      // setShowNftList(nftList);
      await fetchUnstakeNfts();
    }
    setShowNftToggle(!showNftToggle);
    setToStake([]);
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="dashrewards nftsec agmilock-div">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12 ">
                  <div className="nftheading upcomming ">
                    <div className="token token-div1">
                      <h4>NFT</h4>
                      <h6>5000</h6>
                      <div className="nftbtn d-flex mt-5 mb-5">
                        {showNftToggle ? (
                          <button
                            className="text-nowrap"
                            onClick={submitNftStake}
                          >
                            Click to stake NFT (
                            {toStake.length > 0 ? toStake.length : 0})
                          </button>
                        ) : (
                          <button
                            className="text-nowrap"
                            onClick={submitNftUnstake}
                          >
                            Click to unstake NFT (
                            {toStake.length > 0 ? toStake.length : 0})
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12 lockbox">
                  <div className="nftbox upcomming">
                    <div className="token">
                      <h4>TOKEN</h4>
                      <h6 className="tokencount">5000</h6>
                      <div className="tokenbtn d-flex mt-2">
                        <Button onClick={handleShow}>Stake</Button>
                        <Modal
                          show={show}
                          onHide={handleClose}
                          backdrop="static"
                          keyboard={false}
                          centered
                        >
                          <Modal.Header closeButton></Modal.Header>
                          <Modal.Body
                            style={{
                              padding: "35px 15px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              className="input-container"
                              style={{ height: "50px" }}
                            >
                              <i className="fas fa-solid fa-key"></i>
                              <input
                                className="inputtoken"
                                type="number"
                                name="password"
                                id=" "
                                placeholder="Enter your count"
                              />
                            </div>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              className="send-button"
                              onClick={handleShowconformation}
                            >
                              Send
                            </Button>
                          </Modal.Footer>
                        </Modal>
                        <Modal
                          show={showconformation}
                          onHide={handleClosemodal}
                        >
                          <Modal.Header closeButton></Modal.Header>
                          <Modal.Body>
                            Are You Sure You Want To Processed.
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              className="send-button"
                              onClick={handleClosemodal}
                            >
                              Cancel
                            </Button>
                            <Button
                              className="send-button"
                              onClick={handleClose}
                            >
                              Process
                            </Button>
                          </Modal.Footer>
                        </Modal>

                        <Button onClick={handleShow}>UnStake</Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="row">
           <div className="col-g-12">
            <div className="row">
                <div className="col-lg-8">
                <div className="dashrewards">
            <div className="nftheading">
                <h1>NFT</h1>
                <p>5000</p>
                <div className="nftbtn d-flex mt-5">
                  <button onClick={addclub}>Stake</button>
                  <button onClick={addclub}>UnStake</button>
                </div>
              </div>
            </div>
                </div>
            </div>
           
            </div> 
        </div> */}
        <div className="row cardsrow">
          <div className="d-flex justify-content-between">
            <div className="nftheading">
              <h3 className="nftcardheading text-nowrap">
                {showNftToggle ? "Unstake NFTs" : "Staked NFTs"}
              </h3>
            </div>
            <div className="allstake agmi-locker">
              <span className="nftbtn allbtn">
                <button onClick={stakeNftHandler} className="text-nowrap">
                  <div className="inner-border-gradient">
                    {showNftToggle ? "View Staked NFTs" : "View Unstake NFTs"}
                  </div>
                </button>
              </span>
            </div>
          </div>

          {showNftList &&
            showNftList.map((item, i) => (
              <div key={Date.now() + i} className=" col-lg-3 col-md-6 col-12 ">
                <div className="nftcards">
                  <div className="cardnumber">
                    {/* {addtoclub && ( */}
                    <label className="c-custom-checkbox">
                      <input
                        type="checkbox"
                        onClick={() => stakeHandler(item)}
                        checked={item.presentInClub}
                      />
                      <svg
                        className="nftcheck lockcheck"
                        viewBox="0 0 60 40"
                        aria-hidden="true"
                        focusable="false"
                      >
                        <path
                          d="M21,2 C13.4580219,4.16027394 1.62349378,18.3117469 3,19 C9.03653312,22.0182666 25.2482171,10.3758914 30,8 C32.9363621,6.53181896 41.321398,1.67860195 39,4 C36.1186011,6.8813989 3.11316157,27.1131616 5,29 C10.3223659,34.3223659 30.6434647,19.7426141 35,18 C41.2281047,15.5087581 46.3445303,13.6554697 46,14 C42.8258073,17.1741927 36.9154967,19.650702 33,22 C30.3136243,23.6118254 17,31.162498 17,34 C17,40.4724865 54,12.4064021 54,17 C54,23.7416728 34,27.2286213 34,37"
                          stroke-width="4"
                          fill="none"
                          stroke-dasharray="270"
                          stroke-dashoffset="270"
                        ></path>
                      </svg>
                    </label>
                    {/* )} */}
                    <p> #{item.identifier}</p>
                  </div>
                  <div className="nftimg">
                    <img src={item.image_url} alt="axolittle" />
                  </div>
                  <div className="card_data d-flex">
                    <div className="cardname lockcardname">
                      <p>{item.name}</p>
                      <p className="cardrate">0.0027 ETH</p>
                    </div>
                    {/* <div className="viewbtn">
                      <button>VIEW NFT</button>
                    </div> */}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default AgmiLock;
