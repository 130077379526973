import { useEffect, useState } from "react";

const CountdownTimer = ({ endDate }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(endDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const { days, hours, minutes, seconds } = timeLeft;

  return (
    <div className="counter-main">
      <div className="days counter">
        <div className=" day1 day" id="days">
          {days < 10 ? `0${days}` : days}
        </div>
        <span>Days</span>
      </div>
      <div className="hours counter">
        <div className="hour day" id="hours">
          {hours < 10 ? `0${hours}` : hours}
        </div>
        <span>Hours</span>
      </div>
      <div className="minutes counter">
        <div className="minute day" id="minutes">
          {minutes < 10 ? `0${minutes}` : minutes}
        </div>
        <span>Minutes</span>
      </div>
      <div className="seconds counter">
        <div className="second day" id="seconds">
          {seconds < 10 ? `0${seconds}` : seconds}
        </div>
        <span>Seconds</span>
      </div>
    </div>
  );
};

export default CountdownTimer;
