import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
const AdminSideNav = () => {
  const initialValues = {
    addquest: false,
    geteligible: false,
    allUser: false,
  };
  const [sideActive, setSideActive] = useState(initialValues);

  return (
    <nav
      className="navbar navbar-expand-lg navbar-dashboard"
      style={{ padding: "0px" }}
    >
      {/* <a className="navbar-brand" href="#">Navbar</a> */}
      {/* <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContents"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button> */}
      <div 
      // className="collapse navbar-collapse" 

      id="navbarSupportedContents">
        <div className="w-100" style={{ height: "100vh" }}>
          {/* <div className="profile-navbar">
            <div className="userimg">
              <img
                src="https://besturls.com/assets/images/users/149071.png"
                alt=""
                style={{ width: "60px" }}
              />
            </div>
            <div className="username">
              {" "}
              <h6 style={{ fontWeight: "600", fontSize: "18px" }}>Welcome!</h6>
            </div>
          </div> */}
          <div className="profile-sidenav-data">
            <NavLink className="Links" to="/admin/allUsers">
              <div
                className={
                  sideActive.allUser ? "sidenavSingle active" : "sidenavSingle"
                }
                onClick={() =>
                  setSideActive({ ...initialValues, allUser: true })
                }
              >
                <div className="sidenav-flex">
                  <div className="sidenavImage">
                    <img
                      src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1713851601/icons8-male-user-32_mxdlmz.png"
                      alt=""
                    />
                  </div>
                  All Users
                </div>
              </div>
            </NavLink>
            <NavLink className="Links" to="/admin/addquest">
              <div
                className={
                  sideActive.addquest ? "sidenavSingle active" : "sidenavSingle"
                }
                onClick={() =>
                  setSideActive({ ...initialValues, addquest: true })
                }
              >
                <div className="sidenav-flex">
                  <div className="sidenavImage">
                    <img
                      src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1714478411/icons8-quest-30_y3dbkd.png"
                      alt=""
                    />
                  </div>
                Quest
                </div>
              </div>
            </NavLink>
            
            <NavLink className="Links" to="/admin/geteligible">
              <div
                className={
                  sideActive.geteligible
                    ? "sidenavSingle active"
                    : "sidenavSingle"
                }
                onClick={() =>
                  setSideActive({ ...initialValues, geteligible: true })
                }
              >
                <div className="sidenav-flex">
                  <div className="sidenavImage">
                    <img
                      src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1714478401/icons8-analysis-30_jabhng.png"
                      alt=""
                    />
                  </div>
                  Analysis
                </div>
              </div>
            </NavLink>
          </div>{" "}
        </div>
      </div>
    </nav>
  );
};

export default AdminSideNav;
