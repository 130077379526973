import React, { useEffect, useRef, useState } from "react";
import moment from "moment";

import * as API from "../../utils/axios/apis";

import "./Message.css";

const Chat = ({ receiverUser, memoChild, fetchChatHistory, chatHistory }) => {
  const scroll = useRef();

  const [inputMsg, setInputMsg] = useState("");
  const [userDetail, setUserDetail] = useState(
    JSON.parse(localStorage.getItem("userDetail")) ?? null
  );

  useEffect(() => {
    scroll.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatHistory]);

  const sendHandler = async () => {
    if (!inputMsg) return;
    const data = {
      reciverId: receiverUser.userId,
      content: inputMsg,
    };
    const res = await API.SendMessage(data);

    if (res.data.status) {
      fetchChatHistory(receiverUser.userId);
    }
    setInputMsg("");
  };

  const newMsgHandler = () => {
    fetchChatHistory(receiverUser.userId);
  };
  return (
    <>
      <div className="ChatHead">
        {receiverUser.userId !== "" && (
          <>
            <li className="group">
              <div className="avatar">
                <img
                  src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1713348634/d9dff01871c02184ad2bac593db410f6_cinyyv.webp"
                  alt=""
                />
              </div>
              <p className="GroupName">{receiverUser.userName}</p>
            </li>
            <div className="callGroup">
              <i class="fa-solid fa-arrows-rotate" onClick={newMsgHandler}></i>
              {/* <i className="fa-solid fa-phone" /> */}
              {/* <i className="fa-solid fa-video" /> */}
            </div>
          </>
        )}
      </div>
      <div className="MessageContainer">
        <span />
        {/* <div className="messageSeperator">Yesterday</div> */}
        {chatHistory.length > 0 ? (
          chatHistory.map((item, index) => (
            <div
              className={
                item.fromUserAdrress === userDetail.walletAddress
                  ? "message you"
                  : "message me"
              }
            >
              <p className="messageContent" ref={scroll}>
                {item.content}
              </p>
              <div className="messageDetails">
                <div className="messageTime">
                  {moment(item.sentDate).calendar()}
                </div>
                {/* <i className="fa-solid fa-check" /> */}
              </div>
            </div>
          ))
        ) : (
          <>
            <div className="message-before">
              <img
                src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1714030548/14363589-removebg-preview_kwb8bc.png"
                alt=""
              />
              <h4>Stay connected without needing your phone online!</h4>
              <div className="messages-info  d-flex">
                <span>
                  <i className="fa-solid fa-lock"></i>
                </span>
                <p>Your Personal Messages Are End-to-End Encrypted</p>
              </div>
            </div>
          </>
        )}
      </div>
      {receiverUser.userId !== "" && (
        <form id="MessageForm">
          <input
            type="text"
            id="MessageInput"
            value={inputMsg}
            onChange={(e) => setInputMsg(e.target.value)}
            onKeyDown={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <button className="Send" type="button" onClick={sendHandler}>
            <i className="fa-solid fa-paper-plane" />
          </button>
        </form>
      )}
    </>
  );
};

export default React.memo(Chat);
