import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import "./Faq.css"
const Faq = () => {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  const faqData = [
    {
      question: 'What does "AGMI" mean?',
      answer:'"AGMI" stands for " All Gonna Make It." Its a motivational phrase used to encourage others, especially in online communities, implying that success is achievable for everyone.',
    },
    {
      question: 'What is the purpose of the AGMI project?',
      answer:
        'The AGMI project aims to foster a sense of community and motivation among its members. It encourages individuals to support each other in achieving their goals and aspirations.',
    },
    {
      question: 'How can I get involved with the AGMI community?',
      answer:
        'To get involved with the AGMI community, you can join our Discord server, participate in community events and challenges, and engage with other members on social media platforms.',
    },
    {
      question: 'How does AGMI ensure a positive and respectful community environment?',
      answer:
        'AGMI has community guidelines in place to promote a positive and respectful environment. Members are encouraged to treat each other with kindness and respect, and any behavior that goes against these guidelines is addressed promptly.',
    },
  
  ];
  const faqData1 = [
    {
      question: 'What does "AGMI" mean?',
      answer:'"AGMI" stands for " All Gonna Make It." Its a motivational phrase used to encourage others, especially in online communities, implying that success is achievable for everyone.',
    },
    {
      question: 'What is the purpose of the AGMI project?',
      answer:
        'The AGMI project aims to foster a sense of community and motivation among its members. It encourages individuals to support each other in achieving their goals and aspirations.',
    },
    {
      question: 'How can I get involved with the AGMI community?',
      answer:
        'To get involved with the AGMI community, you can join our Discord server, participate in community events and challenges, and engage with other members on social media platforms.',
    },
    {
      question: 'How does AGMI ensure a positive and respectful community environment?',
      answer:
        'AGMI has community guidelines in place to promote a positive and respectful environment. Members are encouraged to treat each other with kindness and respect, and any behavior that goes against these guidelines is addressed promptly.',
    },
  
  ];

  return (
    <section className="faq" style={{  minHeight: '100vh' }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-12 col-lg-12 text-center"></div>
        </div>
        <div className="row">
          <div className="col-xl-6 col-lg-6">

            <Accordion defaultActiveKey="0">
              {faqData.map((faq, index) => {
                
                return(
                  <Card key={index}>
                    <Accordion.Item  eventKey={index.toString()}>
                    <Accordion.Header
                      as={Button}
                      variant=""
                     
                      // onClick={() =>{ handleAccordionClick(index); console.log(index,'ix')}}
                    >
                      {faq.question}
                    </Accordion.Header>
 
                  <Accordion.Body eventKey={index.toString()}>
                    <Card.Body>{faq.answer}</Card.Body>
                  </Accordion.Body>
                    </Accordion.Item>
                </Card>
                )
              })}
            </Accordion>
          </div>
          <div className="col-xl-6 col-lg-6">

            <Accordion defaultActiveKey="0">
              {faqData1.map((faq, index) => {
                
                return(
                  <Card key={index}>
                    <Accordion.Item  eventKey={index.toString()}>
                    <Accordion.Header
                      as={Button}
                      variant=""
                     
                      // onClick={() =>{ handleAccordionClick(index); console.log(index,'ix')}}
                    >
                      {faq.question}
                    </Accordion.Header>
 
                  <Accordion.Body eventKey={index.toString()}>
                    <Card.Body>{faq.answer}</Card.Body>
                  </Accordion.Body>
                    </Accordion.Item>
                </Card>
                )
              })}
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
