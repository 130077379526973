import { createContext, useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import SideNav from "./Common/SideNav";
import WebsiteLayout from "./Common/WebsiteLayout";
import Dashboard from "./Component/Dashboard";
import Home from "./Component/Home/Home";
import Levels from "./Component/Levels/Levels";

import Stake from "./Component/Stake/Stake";
import Airdrops from "./Component/Airdrops/Airdrops";
import Perks from "./Component/Perks/Perks";
import Profile from "./Component/Profile/Profile";
import AgmiLock from "./Component/AgmiLock/AgmiLock";
import Presale from "./Component/Presale/Presale";
import AdminAddQuest from "./Component/Admin/components/AdminAddQuest";
import Rewards from "./Component/Rewards/Rewards";
import Admin from "./Component/Admin/Admin";
import AdminGetEligible from "./Component/Admin/components/AdminGetEligible";
import "./App.css";
import Bridge from "./Component/Bridge/Bridge";
import AdminWebsiteLayout from "./Common/AdminWebsiteLayout";
import Messages from "./Component/Message/Messages";
import Faq from "./Component/Faq/Faq";
import AllUser from "./Component/Admin/components/AllUser";
import AdminLogin from "./Component/Admin/components/AdminLogin";

export const connectedWalletAddress = createContext();

function App() {
  const [walletAddress, setWalletAddress] = useState(null);
  const [userDetail, setUserDetail] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("accesskeyforenterintothesystem");
    const getWalletAddress = localStorage.getItem("walletAddress");
    if (token && getWalletAddress) {
      setWalletAddress(getWalletAddress);
    }
  }, []);

  return (
    <connectedWalletAddress.Provider
      value={{ walletAddress, setWalletAddress, userDetail, setUserDetail }}
    >
      <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/presale" element={<Presale />} />
            {/* <Route path="/dashboard" element={<SideNav />} /> */}
            <Route path="/dashboard" element={<WebsiteLayout />}>
              {/* <Route path="/dashboard" element={<Stake />} /> */}
              {/* <Route path="/dashboard/profile" element={<Profile />} /> */}
              <Route path="/dashboard/airdrops" element={<Airdrops />} />
              <Route path="/dashboard/profile" element={<Profile />} />
              <Route path="/dashboard/rewards" element={<Rewards />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/dashboard/message" element={<Messages />} />
              <Route path="/dashboard/AgmiLock" element={<AgmiLock />} />
              <Route path="/dashboard/perks" element={<Perks />} />
              <Route path="/dashboard/nft-bridge" element={<Bridge />} />
              <Route path="/dashboard/faq" element={<Faq />} />
            </Route>
            <Route path="/admin/login" element={<AdminLogin />} />
            <Route path="/admin" element={<AdminWebsiteLayout />}>
              <Route path="/admin/addquest" element={<AdminAddQuest />} />
              <Route path="/admin/geteligible" element={<AdminGetEligible />} />
              <Route path="/admin/allUsers" element={<AllUser />} />
            </Route>
            {/* <Route path="/dashboard" element={<SideNav />} /> */}
          </Routes>
        </Router>
      </div>
    </connectedWalletAddress.Provider>
  );
}

export default App;
