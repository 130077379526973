import React, { useEffect, useContext, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import { checkAddress } from "../helper";
import * as API from "../utils/axios/apis";
import { connectedWalletAddress } from "../App";
import CountDownTimer from "../Common/CountDownTimer";
import { Levels } from "../utils/constant/index";

import "./Dashboard.css";

const Dashboard = () => {
  const navigate = useNavigate();
  const { setUserDetail } = useContext(connectedWalletAddress);

  const [dashInfo, setDashInfo] = useState(null);

  useEffect(() => {
    if (!checkAddress()) navigate("/");
  }, [navigate]);

  useEffect(() => {
    (async () => {
      const res = await API.GetUserProfile();
      localStorage.setItem("userDetail", JSON.stringify(res.data.data));
      setUserDetail(res.data.data);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await API.getDashboardData().then((data) => setDashInfo(data.data.data));
    })();
  }, []);

  return (
    <div>
      <div className="container">
        {/* <div className="row">
          <div className="col-lg-12">
            <div className="dashrewards">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="totalreward ">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 mt-5">
                        <h1>Total Rewards Earn</h1>
                        <h6>5000</h6>
                        <div className="rewardsbtn">
                          <button className="rewardbutton mt-3">
                            All Rewards
                          </button>
                        </div>
                      </div>
                  
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="row mt-3 mb-3 upcommingrow">
                    <div className="upcoming_bagde mt-4">
                      <img
                        src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1713520050/UPCOMING_AIRDROPS_ICON2_ibc8hy.png"
                        alt=""
                      />
                    </div>

                    <div className="upcomming">
                      <img
                        src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1713377007/logo-img_vrdgo3.png"
                        alt=""
                      />
                      <div className="upcomingreward">
                        <h1>500</h1>
                      </div>
                      <div className="timer">
                        <p>Expires On</p>
                        <div className="counter-main">
                          <div className="days counter">
                            <div className=" day1 day" id="days">
                              00
                            </div>
                            <span>Days</span>
                          </div>
                          <div className="hours counter">
                            <div className="hour day" id="hours">
                              00
                            </div>
                            <span>Hours</span>
                          </div>
                          <div className="minutes counter">
                            <div className="minute day" id="minutes">
                              00
                            </div>
                            <span>Minutes</span>
                          </div>
                          <div className="seconds counter">
                            <div className="second day" id="seconds">
                              00
                            </div>
                            <span>Seconds</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="row">
              <div className="col-lg-6 col-md-5">
                <div
                  className="totalreward upcomming "
                  style={{
                    height: "12rem",
                    padding: "10px 15px",
                    margin: "20px 0 0 0",
                  }}
                >
                  <div className="row ">
                    <h4>Total Rewards Earn</h4>
                    <h6>{dashInfo?.rewardsEarned}</h6>
                    <NavLink to={"/dashboard/rewards"}>
                      <div className="rewardsbtn">
                        <button className="rewardbutton mt-3">
                          All Rewards
                        </button>
                      </div>
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-7 d-flex">
                <div className="upcomming d-flex" style={{ gap: "5rem" }}>
                  <div className="upcomingreward">
                    <img
                      src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1713377007/logo-img_vrdgo3.png"
                      alt=""
                    />
                    <h6>500 Tokens</h6>
                  </div>
                  <div className="timer">
                    <h5 className="airdropheading">Upcoming Airdrop</h5>
                    <p>Expires On</p>
                    <CountDownTimer
                      endDate={Date.now() + 10 * 24 * 60 * 60 * 1000}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* levels */}

        <div className="row">
          <div className="col-lg-8">
            <section id="vipLevel">
              <div className="container">
                <div className="vipLevelCase mt-3">
                  <h2 style={{ color: "#fff", marginTop: "15px" }}>Level-0</h2>
                  <div className="innerVipLevelCase">
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="col">
                        <div className="prevLevel p-2 text-center">
                          <img
                            src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1713444102/Group_44_slljbj.png"
                            alt=""
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="progressbarOuter">
                          <div className="userName mb-2" />
                          <div className="userPoints mb-2">
                            {dashInfo?.currentLevel}
                          </div>
                          <div className="progressBar mb-2">
                            {Levels.map((item, index) => (
                              <div
                                key={Date.now() + index}
                                className={`individual ${
                                  index < dashInfo?.currentLevel ? "active" : ""
                                }`}
                              />
                            ))}
                          </div>
                          <div className="levelDiff">
                            <div className="currentLevel">
                              Current-Level:{" "}
                              <span className="currentLevelValue"></span>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="nextLevel p-2 text-center">
                          <img
                            src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1713444100/Group_43_s4tx3s.png"
                            alt=""
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="col-lg-4">
            <div className="">
              <div className="participate">
                <div className="row">
                  <div className="col-lg-12  col-md-12">
                    <h1>{dashInfo?.participatedCount}</h1>
                    <h6>Participate</h6>
                  </div>

                  {/* <div className="col-lg-4">
                  <div className="participate-count">
                    <h1>20</h1>
                  </div>
                </div> */}
                </div>
              </div>
            </div>
            <div className="">
              <div className="participate" style={{ background: "#9698D6" }}>
                <div className="row">
                  <div className="col-lg-12">
                    <h1>{dashInfo?.eligibleCount}</h1>
                    <h6>Eligible</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="participate" style={{ background: "#FF7F8B" }}>
                <div className="row">
                  <div className="col-lg-12">
                    <h1>{dashInfo?.qualifiedCount}</h1>
                    <h6>Qualified</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* participate-info */}
      </div>
    </div>
  );
};

export default Dashboard;
