import { useContext, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Web3 from "web3";
import { connectedWalletAddress } from "../../App";
import { shortheString } from "../../helper";
import * as API from "../../utils/axios/apis";
import Presale from "../Presale/Presale";
import { web3Modal } from "../../utils/walletConnect/metamaskConnect";
import "./Home.css";

const Home = () => {
  const navigate = useNavigate();
  const { walletAddress, setWalletAddress } = useContext(
    connectedWalletAddress
  );

  useEffect(() => {
    const token = localStorage.getItem("accesskeyforenterintothesystem");
    const getWalletAddress = localStorage.getItem("walletAddress");
    if (token && getWalletAddress) {
      setWalletAddress(getWalletAddress);
    }
  }, []);

  const connectWalletHandler = async () => {
    try {
      if (window.ethereum) {
        // const web3 = new Web3(window.ethereum);
        // const accounts = await web3.eth.getAccounts();
        // const accountAddress = accounts[0];
        const provider = await web3Modal.connect();
        const web3 = new Web3(provider);
        const accountAddress = web3.currentProvider.selectedAddress;

        let Getnonce = await API.GetnonceMeta(accountAddress);
        let message =
          "Hello from agmi.club, You have sign this for login. To prevent hackers from using your account here is your unique id " +
          Getnonce.data.Nonce;
        let signature = await web3.eth.personal.sign(
          message,
          accountAddress,
          "test password!"
        );

        const signInResponse = await API.SignInMeta({
          walletAddress: accountAddress,
          signature: signature,
        });

        localStorage.setItem(
          "accesskeyforenterintothesystem",
          signInResponse.data.AccessToken
        );
        localStorage.setItem("walletAddress", signInResponse.data.ethAddess);
        setWalletAddress(signInResponse.data.ethAddess);
      } else {
        toast.info("Please install metamask!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (err) {
      console.log("ERROR: ", err);
    }
  };

  return (
    <>
      <div className="body-bg">
        {/* <div className="bg-gif"> </div> */}
        {/* <div className="solar-system"> */}
        <div className="galaxy"></div>
        <div className="planet mercury"></div>
        <div className=" venus"></div>
        <div className="stars"></div>
        <div className="mars">
          <div className="land-here-text">
            <h5>
              Click Here <i className="fa fa-arrow-circle-down"></i>
            </h5>
          </div>
          <NavLink to={"/presale"}>
            <div className="mars-img">
              {/* <div className="presale-text">Presale</div> */}
            </div>
          </NavLink>
        </div>
        <div className="star-1"></div>
        <div className="star2"></div> <div className="star3"></div>
        <div className="star4"></div> <div className="star5"></div>
        <div className="star6"></div>
        <div className="star7"></div>
        <div className="star8"></div>
        <div className="star9"></div>
        <div className="star11"></div>
        <div className="star10"></div> <div className="star11"></div>
        <div className="star12"></div>
        <div className="axo-little"></div>
        <div className="axo-mini"></div>
        <div className="axo-mini2"></div>
        {/* </div> */}
        <div className="container ">
          {/* navbar */}
          {/* <nav className="navbar  navbar-expand-lg ">
            <div className="container-fluid">
              <NavLink
                to={"/"}
                className="navbar-brand"
                style={{ textDecoration: "none" }}
              >
                <img style={{width:'10rem'}} src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1714194361/Group_190_2_twdkte.png" alt="" />
              </NavLink>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav m-auto mb-2 mb-lg-0">
                 
                </ul>
                <form className="" role="search">
                  <button
                    className="btn connect-wallet-btn"
                    type="button"
                    onClick={() => {
                      if (!walletAddress) {
                        connectWalletHandler();
                      }
                    }}
                  >
                    {walletAddress
                      ? shortheString(walletAddress)
                      : "Connect Wallet"}
                  </button>
                </form>
              </div>
            </div>
            <hr />
          </nav> */}


          <ul class="nav ">
  <li class="nav-item mt-3">
    <NavLink
                to={"/"}
                className="navbar-brand"
                style={{ textDecoration: "none" }}
              >
                <img style={{width:'10rem'}} className='mobilelogo' src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1714194361/Group_190_2_twdkte.png" alt="" />
              </NavLink>
  </li>
  <form className=" ms-auto mt-3 indexplanet" role="search ">
                  <button
                    className="btn connect-wallet-btn"
                    type="button"
                    onClick={() => {
                      if (!walletAddress) {
                        connectWalletHandler();
                      }
                    }}
                  >
                    {walletAddress
                      ? shortheString(walletAddress)
                      : "Connect Wallet"}
                  </button>
                </form>
</ul>
          {/* banner */}
          <div className="banner-section">
            <div className="banner container m-auto">
              <h1 className="">
                Join the Web 3.O <br />
                revolution
              </h1>
              <p>Be a part of India’s first Web3 community</p>
              {walletAddress ? (
                <button
                  className="connect-wallet-btn connect-wallet-banner"
                  onClick={() => navigate("/dashboard")}
                >
                  Go to dashboard
                </button>
              ) : (
                <button
                  className="connect-wallet-btn connect-wallet-banner"
                  onClick={connectWalletHandler}
                >
                  Connect Wallet
                </button>
              )}
            </div>
          </div>{" "}
        </div>{" "}
        {/* <div className="body-bg-2"> */}
        <div className="bg-glow"></div>
        <div className="container cont-home">
          {/* presale */}
          <div className="presale-home">
            <div className="presale-boxss">{/* <Presale /> */}</div>
          </div>
          {/* about */}
          <div className="about-section">
            <h2>Who are we</h2>
            <p>#ALL ARE GONNA MAKE IT</p>
            <h6 className="pt-3">
              A Community of students sharing their enthusiasm and belief
              towards the world of Web 3.0 <br /> and its principles of
              transparency and equal accessibilty
            </h6>
          </div>
          {/* what agmi */}
          <div className="what-agmi">
            <div className="row">
              <div className="col-lg-6 what-is-agmi-content">
                {" "}
                <h2>What does AGMI Club do?</h2>
                <p>
                  It provides a platform to all the students who are eager to
                  explore the Web 3.0 space. To make this possible, AGMI
                  collaborates with institutions and companies to bridge the gap
                  between them and enhance the learning experience, making it
                  seamless for everyone.
                </p>
              </div>
              <div className="col-lg-6">
                <img
                  src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1714366448/Group_191_1_julv6j.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          {/* objectives */}
          <div className="objectives-agmi">
            <h2>Objectives to achieve</h2>
            <div className="row">
              <div className="col-lg-3">
                {" "}
                <div className="obj-agmi-span">
                  <p>
                    {" "}
                    Foster a sense of unity and support within communities by
                    promoting the idea that everyone can succeed together.
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                {" "}
                <div className="obj-agmi-span obj-agmi-span-2">
                  <p>
                    {" "}
                    Foster a sense of unity and support within communities by
                    promoting the idea that everyone can succeed together.
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                {" "}
                <div className="obj-agmi-span">
                  <p>
                    {" "}
                    Foster a sense of unity and support within communities by
                    promoting the idea that everyone can succeed together.
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                {" "}
                <div className="obj-agmi-span obj-agmi-span-2">
                  <p>
                    {" "}
                    Foster a sense of unity and support within communities by
                    promoting the idea that everyone can succeed together.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* why-agmi */}
          <div className="why-agmi">
            <h2>
              Why should you join <br /> AGMI Club?
            </h2>
            <div className="row why-content">
              <div className="col-lg-6">
                <div className="why-content-text">
                  <h3>Unlock the world of unlimited opportunities</h3>
                  <p>
                    Be an early adopter to learn & grow through our abundant
                    resources in the domain of Web 3.0, Blockchain and
                    Cryptocurrencies.
                  </p>
                  <button className="view-more-btn">View More</button>
                </div>{" "}
              </div>
              <div className="col-lg-6">
                <div className="why-content-img">
                  <img
                    src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1714366460/Frame_12_1_1_l8kzqh.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="row why-content-2">
              <div className="col-lg-6 d-flex">
                {" "}
                <div className="why-content-img-2">
                  <img
                    src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1714197332/Screenshot_2024-04-27_112450_u7x1ox.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-lg-6">
                {" "}
                <div className="why-content-text">
                  <h3>Connect with the best of Web3 wizards</h3>
                  <p>
                    Network with the leading Web3 experts through exclusive
                    events and programs.
                  </p>
                  <button className="view-more-btn">Get Access</button>
                </div>{" "}
              </div>
            </div>
          </div>
          {/* How to buy */}
          <div className="how-to-buy">
            <h2> How To Buy</h2>
            <div className="how-to-buy-img">
              <img
                className="desktop-view"
                src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1713426405/roadmap_2_jbxqzq.png"
                alt=""
              />
              <img
                className="mobile-how-to-buy"
                src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1714197731/Frame_11_1_1_xrulgw.png"
                alt=""
              />
              <div className="how-to-buy-step1">
                <h4>Register</h4>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae
                  voluptas aliquam enim dolor? Repellat, reiciendis saepe quas
                  delectus quibusdam iste?
                </p>
              </div>
              <div className="how-to-buy-step2">
                <h4>Buy</h4>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae
                  voluptas aliquam enim dolor? Repellat, reiciendis saepe quas
                  delectus quibusdam iste?
                </p>
              </div>
              <div className="how-to-buy-step3">
                <h4>CA</h4>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae
                  voluptas aliquam enim dolor? Repellat, reiciendis saepe quas
                  delectus quibusdam iste?
                </p>
              </div>{" "}
              <div className="how-to-buy-step4">
                <h4>Done</h4>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae
                  voluptas aliquam enim dolor? Repellat, reiciendis saepe quas
                  delectus quibusdam iste?
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* tokenomics */}
        <div className="tokenomics">
          <div className="tokenomics-bg"></div>
          <div className="container">
            <div className="tokenomics-img">
              <h2>Tokenomics</h2>
              <div className="tokenomics-section">
                <div className="row">
                  <div className="col-lg-7">
                    <h3>The real value of AGMI Token</h3>
                    <p>
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                      Amet, nisi.
                    </p>

                    <div className="tokenomics-chart">
                      <img
                        src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1713435796/graph_3_pwj8tc.png"
                        alt=""
                      />
                      <div className="tokenomics-chart-center">
                        <h4> Total Supply</h4>
                        100,000,000,000
                      </div>
                      {/* <div className="tokenomics-chart-part">
                        50% <br /> Presale Airdrop
                      </div>
                      <div className="tokenomics-chart-part2">
                        40% <br /> Liquidity Pool
                      </div> */}
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="tokenomics-data-flex">
                      <div className="tokenomics-data">
                        <div className="tokenomics-data-div div-1-tokenomics">
                          <h5>50%</h5>
                          <p>Airdrop Presale</p>
                        </div>
                      </div>{" "}
                      <div className="tokenomics-data">
                        <div className="tokenomics-data-div div-2-tokenomics">
                          <h5>40%</h5>
                          <p>Liquidity Pool</p>
                        </div>
                      </div>{" "}
                      <div className="tokenomics-data">
                        <div className="tokenomics-data-div div-3-tokenomics">
                          <h5>5%</h5>
                          <p>Airdrop</p>
                        </div>
                      </div>{" "}
                      <div className="tokenomics-data">
                        <div className="tokenomics-data-div div-4-tokenomics">
                          <h5>3%</h5>
                          <p>CEX and Marketing</p>
                        </div>
                      </div>{" "}
                      <div className="tokenomics-data">
                        <div className="tokenomics-data-div div-5-tokenomics">
                          <h5>2%</h5>
                          <p>Dev</p>
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>{" "}
          <div className="bg-glow">
            <img
              src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1713359689/Ellipse_6_1_h2xiai.png"
              alt=""
            />
          </div>
          <div className="tokenomics-bg-bottom"></div>
          <div className="tokenomics-bg-bottom-circle"></div>
        </div>
        {/* footer */}{" "}
        <div className="footer">
          <div className="footer-desc">
            <p style={{ marginBottom: 0 }}>
              Axo Holders are not associated with AGMI or its creation. This
              token is paying homage to a meme we all love and recognize. <br />
              AGMI is a token with no intrinsic value or utility.
            </p>
          </div>
          <div className="footer-flex container">
            <div className="logo-footer" style={{ cursor: "pointer" }}>
              <NavLink
                to={"/"}
                className="navbar-brand"
                style={{ textDecoration: "none" }}
              >
                <img style={{width:'10rem'}} className="mobilefooter" src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1714194361/Group_190_2_twdkte.png" alt="" />
              </NavLink>
            </div>
            <div className="footer-center-section ">
              <div
                className="social-links-footer"
                style={{ cursor: "pointer" }}
              >
                <a href="/" target="_blank">
                  {" "}
                  <i className="fa-brands fa-x-twitter fa-lg"></i>
                </a>
                <a href="/" target="_blank">
                  {" "}
                  <i className="fa-brands fa-telegram ms-3 fa-lg"></i>
                </a>
                <a href="/" target="_blank">
                  {" "}
                  <i className="fa-brands fa-medium ms-3 fa-lg"></i>
                </a>
              </div>
            </div>

            <form className="d-flex" role="search">
              <button className="btn connect-wallet-btn" type="submit">
                Buy Now
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
