import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
// import LandingNavbar from "./LandingNavbar";
import SideNav from "./SideNav";
import CommonNavbar from "./CommonNavbar/CommonNavbar";
import AdminSideNav from "./AdminSideNav";
import Pagination from "./Pagination/Pagination";
const AdminWebsiteLayout = () => {
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width:550px)").matches
  );
  useEffect(() => {
    window.matchMedia("(max-width:550px)").addEventListener("change", (e) => {
      setMatches(e.matches);
      window.location.reload();
    });

    // eslint-disable-next-line
  }, [matches]);
  return (
    <>
      {matches ? (
        <div className="desktopViewNotSupport">
          <div className="text-white">
            <h1 className="px-3 text-center">
              DashBorad is only supported in Desktop View. Please adjust your
              screen resolution
            </h1>
          </div>
        </div>
      ) : (
        <div
          className="CommonComponent"
          style={{ background: "rgb(14, 15, 15)", overflow: "hidden" }}
        >
          <CommonNavbar />
          <div className="admin-dash">
            <div className="row">
              <div className="col-lg-2 col-md-3 col-12 sidenav-bg">
                <AdminSideNav />
              </div>
              <div className="Mainbody mt-5 col-lg-10 col-md-9 col-12">
                <Outlet />
              </div>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      )}
    </>
  );
};

export default AdminWebsiteLayout;
