import React, { useEffect, useState, useContext } from "react";
import TsParticles from "../TsParticles";
import Web3 from "web3";
import axios from "axios";
import ProgressBar from "react-bootstrap/ProgressBar";

import { connectedWalletAddress } from "../../App";
import { exchangeRateETHToUSD } from "../../helper";
import "./Presale.css";
import { setIn } from "formik";

const Presale = () => {
  const [toggle, setToggle] = useState(false);
  const { walletAddress } = useContext(connectedWalletAddress);
  const [showTooltip, setShowTooltip] = useState(false);
  const [copied, setCopied] = useState(false);
  // const [ethToUsdRates, setEthToUsdRates] = useState(null);
  const [ethAmount, setEthAmount] = useState(0);
  const [agmiAmount, setAgmiAmount] = useState(0);
  const [broughtAGMIInEth, setBroughtAGMIInEth] = useState(0);

  const receiverWalletAddress = "0xb6E90C081e295aa6587c8E8db3D437a187a180E9";

  const getWalletBalance = async () => {
    const web3 = new Web3(window.ethereum);

    const weiBalance = await web3.eth.getBalance(receiverWalletAddress);

    const ethBalance = web3.utils.fromWei(weiBalance, "ether");

    setBroughtAGMIInEth(ethBalance);

    // const ethToUsd = ethBalance * ethToUsdRates;
    // setBroughAGMIUSD(parseFloat(ethToUsd).toFixed(4));
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     exchangeRateETHToUSD().then((res) => setEthToUsdRates(res));
  //     getWalletBalance();
  //   }, 5000);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [ethToUsdRates]);

  useEffect(() => {
    // exchangeRateETHToUSD().then((res) => setEthToUsdRates(res));
    getWalletBalance();
  }, []);

  const copyText = (e) => {
    e.preventDefault();
    // const textToCopy = addressMessage.walletAddress;
    const textToCopy = receiverWalletAddress;
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    setCopied(true);
    setShowTooltip(true);
    setTimeout(() => {
      setCopied(false);
      setShowTooltip(false);
    }, 2000);
  };

  const buyAGMIHandler = async () => {
    let params = [
      {
        from: walletAddress,
        to: receiverWalletAddress,
        gas: Number(21000).toString(16),
        gasPrice: Number(2500000).toString(16),
        value: Number(ethAmount * Math.pow(10, 18)).toString(16),
      },
    ];

    let result = await window.ethereum
      .request({
        method: "eth_sendTransaction",
        params,
      })
      .catch((err) => console.log("err", err));

    if (result) {
      getWalletBalance();
    }
    setEthAmount(0);
    setAgmiAmount(0);
  };

  const ethInputHandler = (e) => {
    const ethAmount = e.target.value;
    const ethToAgmi = ethAmount * 10000;
    setEthAmount(ethAmount);
    setAgmiAmount(ethToAgmi);
  };

  return (
    <>
      {/* <TsParticles toggle={toggle} /> */}
      <div className="presale-cont-img">
        <div className="presale-page">
          {" "}
          <div className="presale">
            <div className="container">
              <div className="presale-box">
                {" "}
                <div className="presale-tnc">
                  <h5>
                    <b>
                      {" "}
                      Prior to proceeding, please review the instructions
                      outlined below :
                    </b>
                  </h5>{" "}
                  <br />
                  <p>1. Please select base chain ETH on Base Chain</p>
                  <p>
                    2. Each wallet is subject to a{" "}
                    <b>maximum contribution limit of 0.25 ETH.</b> Contributions
                    exceeding this limit will be disregarded and treated as
                    rounded to 0.25 ETH.
                  </p>
                  <p>
                    3. All wallets contributing 0.25 ETH during the Presale
                    period will be entitled to receive a complimentary Axolittle
                    non-fungible token (NFT) on the Ethereum base chain.
                  </p>
                </div>
                <div className="presale-container">
                  <div className="presale-cont m-auto">
                    <div className="presaleBox text-center">
                      <div className="coinLogo mb-3">
                        <h2 className="ms-2">Mega AGMI Presale</h2>
                      </div>{" "}
                      <div className="rangeOuterPresale ">
                        {/* <div className="progress mt-2 progress-range-style">
                          <div
                            className="progress-bar progress-presale-bar"
                            role="progressbar"
                            aria-valuenow={broughtAGMIInEth}
                            aria-valuemin={0}
                            aria-valuemax={25}
                          >
                            ETH Raised: ETH {broughtAGMIInEth}
                          </div> 
                        </div> */}
                        <ProgressBar
                          now={broughtAGMIInEth}
                          label={`ETH Raised: ${broughtAGMIInEth} ETH`}
                          min={0}
                          max={25}
                        />
                        <div className="usd-raised">
                          ETH Raised: {broughtAGMIInEth} ETH
                        </div>
                        <div className="one-token-price">1 AGMI = $1.020</div>
                        <ul className="nav nav-tabs token-buttons" id="myTab">
                          {/* <li className="" role="presentation">
                        <button
                          className="nav-link active"
                          id="sol-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#sol-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="sol-tab-pane"
                          aria-selected="true"
                        >
                          <div className="sol-btn">SOL</div>
                        </button>
                      </li> */}
                          {/* <li className="nav-item" role="presentation">
                            <button
                              className="nav-link active"
                              id="eth-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#eth-tab-pane"
                              type="button"
                              role="tab"
                              aria-controls="eth-tab-pane"
                              aria-selected="false"
                            >
                              <div className="eth-btn">ETH</div>
                            </button>
                          </li>{" "} */}
                          {/* <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="bnb-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#bnb-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="bnb-tab-pane"
                          aria-selected="false"
                        >
                          <div className="bnb-btn">BNB</div>
                        </button>
                      </li> */}
                        </ul>
                        <div className="tab-content" id="myTabContent">
                          {/* <div
                        className="tab-pane fade "
                        id="sol-tab-pane"
                        role="tabpanel"
                        aria-labelledby="sol-tab"
                        tabIndex="0"
                      >
                        {" "}
                        <br />
                        <p style={{ marginBottom: "0" }}>
                          Send SOL to this address:
                        </p>
                        <div className="input-sol-copy">
                          <input
                            disabled
                            className="sol-address-inputbox"
                            type="text"
                            name="sol-address"
                            id="sol-address"
                            value="0dkjshdvnuidncvuijbdvaindidncvuijbdvainidncvuijbdvaivuijbdvaivuijbdvain"
                          />
                          {!copied && (
                            <i className="fa fa-copy" onClick={copyText}></i>
                          )}
                          {copied && (
                            <i
                              className="fa fa-check"
                              style={{ color: "green" }}
                            ></i>
                          )}
                        </div>
                        <br />
                        <p className="or-text">OR</p>
                        <div className="buy-or-receive">
                          <div className="buy-with-sol">
                            <label htmlFor="buy">Buy with SOL</label>
                            <input
                              className="sol-address-input"
                              type="number"
                              name=""
                              id=""
                              placeholder="0"
                            />{" "}
                          </div>{" "}
                          <div className="buy-with-sol">
                            <label htmlFor="buy">Receive $AGMI</label>
                            <input
                              className="sol-address-input"
                              type="number"
                              name=""
                              id=""
                              placeholder="0"
                            />{" "}
                          </div>
                        </div>{" "}
                        <div
                          id="ethBuyToken"
                          className="buyTokenPresale deactiveValidate"
                        >
                          Buy Now
                        </div>
                      </div> */}
                          <div
                            className="tab-pane fade show active"
                            id="eth-tab-pane"
                            role="tabpanel"
                            aria-labelledby="eth-tab"
                            tabIndex="0"
                          >
                            {" "}
                            <br />
                            <p style={{ marginBottom: "0" }}>
                              Send ETH to this address:
                            </p>
                            <div className="input-sol-copy">
                              <input
                                disabled
                                className="eth-address-inputbox"
                                type="text"
                                name="sol-address"
                                id="sol-address"
                                value={receiverWalletAddress}
                              />{" "}
                              {!copied && (
                                <i
                                  className="fa fa-copy"
                                  onClick={copyText}
                                ></i>
                              )}
                              {copied && (
                                <i
                                  className="fa fa-check"
                                  style={{ color: "green" }}
                                ></i>
                              )}
                            </div>
                            <br />
                            <p className="or-text">OR</p>
                            <div className="buy-or-receive">
                              <div className="buy-with-eth">
                                <label htmlFor="buy">Buy with ETH</label>
                                <input
                                  className="eth-address-input"
                                  type="number"
                                  name=""
                                  id=""
                                  placeholder="0"
                                  value={ethAmount}
                                  onChange={ethInputHandler}
                                />{" "}
                              </div>{" "}
                              <div className="buy-with-eth">
                                <label htmlFor="buy">Receive $AGMI</label>
                                <input
                                  className="eth-address-input"
                                  type="number"
                                  name=""
                                  id=""
                                  placeholder="0"
                                  value={agmiAmount}
                                  disabled={true}
                                />{" "}
                              </div>
                            </div>{" "}
                            <div
                              id="ethBuyToken"
                              className="buyTokenPresale deactiveValidate"
                              onClick={buyAGMIHandler}
                            >
                              Buy Now
                            </div>
                          </div>{" "}
                          {/* <div
                        className="tab-pane fade"
                        id="bnb-tab-pane"
                        role="tabpanel"
                        aria-labelledby="bnb-tab"
                        tabIndex="0"
                      >
                        <br />
                        <p style={{ marginBottom: "0" }}>
                          Send BNB to this address:
                        </p>
                        <div className="input-sol-copy">
                          <input
                            disabled
                            className="bnb-address-inputbox"
                            type="text"
                            name="sol-address"
                            id="sol-address"
                            value="0dkjshdvnuidncvuijbdvaindidncvuijbdvainidncvuijbdvaivuijbdvaivuijbdvain"
                          />{" "}
                          {!copied && (
                            <i className="fa fa-copy" onClick={copyText}></i>
                          )}
                          {copied && (
                            <i
                              className="fa fa-check"
                              style={{ color: "green" }}
                            ></i>
                          )}
                        </div>
                        <br />
                        <p className="or-text">OR</p>
                        <div className="buy-or-receive">
                          <div className="buy-with-bnb">
                            <label htmlFor="buy">Buy with BNB</label>
                            <input
                              className="bnb-address-input"
                              type="number"
                              name=""
                              id=""
                              placeholder="0"
                            />{" "}
                          </div>{" "}
                          <div className="buy-with-bnb">
                            <label htmlFor="buy">Receive $AGMI</label>
                            <input
                              className="bnb-address-input"
                              type="number"
                              name=""
                              id=""
                              placeholder="0"
                            />{" "}
                          </div>
                        </div>{" "}
                        <div
                          id="ethBuyToken"
                          className="buyTokenPresale deactiveValidate"
                        >
                          Buy Now
                        </div>
                      </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
    </>
  );
};

export default Presale;
