import React, { useEffect, useState } from "react";
import * as API from "../../utils/axios/apis";
import "./Rewards.css";
const Rewards = () => {
  const [rewardList, setRewardList] = useState(null);
  useEffect(() => {
    (async () => {
      await API.getRewardHistory().then((res) => console.log(res.data));
    })();
  }, []);
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12  tablecol">
            <div className="rewards-heading">
              <img
                src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1708413858/moneyiconnewpos_xiyto2.png"
                alt=""
              />
              <h1>Rewards History</h1>
            </div>
            <section className="tablesec">
              <div className="tbl-header">
                <table cellPadding={0} cellSpacing={0} className="tablediv">
                  <thead>
                    <tr>
                      <th className="thi">Date</th>
                      <th>User Name</th>
                      <th>Token</th>
                      <th>Quantity</th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div className="tbl-content">
                <table
                  cellPadding={0}
                  cellSpacing={0}
                  border={0}
                  className="tablediv"
                >
                  <tbody>
                    <tr>
                      <td>22/04/2024</td>
                      <td>ekta vaidya </td>
                      <td>50 SOL</td>
                      <td>50</td>
                    </tr>
                    <tr>
                      <td>22/04/2024</td>
                      <td>ekta vaidya </td>
                      <td>50 SOL</td>
                      <td>50</td>
                    </tr>
                    <tr>
                      <td>22/04/2024</td>
                      <td>ekta vaidya </td>
                      <td>50 SOL</td>
                      <td>50</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rewards;
